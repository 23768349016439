import { useEffect, useMemo } from 'react';
import { Provider } from 'react-redux';
import { ROMA_HOST } from 'constants/config';
import { FAVICON, FAVICON_ROMA } from 'constants/icons';
import mixpanelEvents from 'constants/mixpanel';
import { PUBLIC_ROUTES } from 'constants/routeConfig';
import AuthGuard from 'hoc/AuthGuard';
import type { AppProps } from 'next/app';
import { Outfit } from 'next/font/google';
import Head from 'next/head';
import { useRouter } from 'next/router';
import ErrorBoundary from 'pages/ErrorBoundary';
import { store } from 'store';
import { NextPageWithLayout } from 'types';
import { isRomaDashboard } from 'utils/common';
import { trackMixpanel } from 'utils/mixpanel';
import SuperUserRibbon from 'components/banners/SuperUserRibbon';
import NetworkStatus from 'components/network/network-status';
import 'destiny/dist/styles/fonts.css';
import 'styles/globals.css';
import 'destiny/dist/styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';

const outfit = Outfit({ subsets: ['latin'] });

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  isRomaServerCall?: boolean;
};

function App({ Component, pageProps, isRomaServerCall }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page: any) => page);
  const router = useRouter();
  const isRoma = useMemo(() => isRomaDashboard(), []);

  const getComponent = () => {
    if (PUBLIC_ROUTES[router.pathname as keyof typeof PUBLIC_ROUTES])
      return getLayout(
        <AuthGuard>
          <Component {...pageProps} />
        </AuthGuard>
      );

    return <AuthGuard>{getLayout(<Component {...pageProps} />)}</AuthGuard>;
  };

  useEffect(() => {
    trackMixpanel(mixpanelEvents.INITIALIZE, {});
    trackMixpanel(mixpanelEvents.PAGE_NAVIGATION, { url: router.pathname, type: 'PAGE_LOAD' });

    const handleRouteChangeStart = (url: string) => {
      trackMixpanel(mixpanelEvents.PAGE_NAVIGATION, { url, type: 'ROUTE_CHANGE_START' });
    };

    const handleRouteError = (url: string) => {
      trackMixpanel(mixpanelEvents.PAGE_NAVIGATION, { url, type: 'ROUTE_CHANGE_ERROR' });
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeError', handleRouteError);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.on('routeChangeError', handleRouteError);
    };
  }, []);

  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        {isRoma || isRomaServerCall ? (
          <>
            <title>Roma Global</title>
            <link rel='icon' type='image/x-icon' href={FAVICON_ROMA} />
          </>
        ) : (
          <>
            <title>Zamp Finance</title>
            <link rel='icon' type='image/x-icon' href={FAVICON} />
          </>
        )}
      </Head>
      <NetworkStatus />
      <Provider store={store}>
        <ErrorBoundary>
          <div className={`${outfit.className} tw-font-light tw-tracking-[0.03em] tw-text-TEXT_PRIMARY`}>
            {getComponent()}
            <SuperUserRibbon />
          </div>
        </ErrorBoundary>
      </Provider>
    </>
  );
}

App.getInitialProps = async (appContext: any) => {
  const { ctx } = appContext;
  const { req } = ctx;

  const origin = req ? req.headers.host : window.location.origin;

  if (origin?.endsWith(ROMA_HOST)) {
    return { isRomaServerCall: true };
  }

  return { isRomaServerCall: false };
};

export default App;
