import { ROUTE_KEYS, ROUTES_PATH } from 'constants/routeConfig';

export const KEYS_DELIMITER = '::';

export const MONITOR_KEYS = [
  'KeyA',
  'KeyB',
  'KeyC',
  'KeyD',
  'KeyG',
  'KeyH',
  'KeyI',
  'KeyK',
  'KeyM',
  'KeyO',
  'KeyR',
  'KeyT',
  'ShiftLeft',
  'Escape',
  'MetaLeft',
];

/**
 * Keys need to be in ascending order
 * KeyA::ShiftLeft (VALID)
 * ShiftLeft::KeyA (INVALID)
 */
export const KEYBOARD_SHORTCUT_KEYS = {
  NAVIGATE_ACCOUNTS: `KeyA${KEYS_DELIMITER}ShiftLeft`,
  NAVIGATE_CARDS: `KeyC${KEYS_DELIMITER}ShiftLeft`,
  NAVIGATE_HOME: `KeyH${KEYS_DELIMITER}ShiftLeft`,
  NAVIGATE_INVESTMENT_PLANNER: `KeyI${KEYS_DELIMITER}ShiftLeft`,
  NAVIGATE_RECIPIENTS: `KeyB${KEYS_DELIMITER}ShiftLeft`,
  NAVIGATE_REPORTS: `KeyR${KEYS_DELIMITER}ShiftLeft`,
  NAVIGATE_TRANSACTIONS: `KeyT${KEYS_DELIMITER}ShiftLeft`,
  NAVIGATE_TREASURY: `KeyM${KEYS_DELIMITER}ShiftLeft`,
  GOD_MODE: `KeyD${KEYS_DELIMITER}KeyG${KEYS_DELIMITER}KeyG${KEYS_DELIMITER}KeyO${KEYS_DELIMITER}ShiftLeft`,
  ESCAPE: `Escape`,
  SEARCH_SHORTCUT: `KeyK${KEYS_DELIMITER}MetaLeft`,
};

export const SHORTCUT_PROPERTIES = {
  [KEYBOARD_SHORTCUT_KEYS.NAVIGATE_ACCOUNTS]: { path: ROUTES_PATH.ACCOUNTS_V2 },
  [KEYBOARD_SHORTCUT_KEYS.NAVIGATE_CARDS]: { path: ROUTES_PATH.CARDS },
  [KEYBOARD_SHORTCUT_KEYS.NAVIGATE_HOME]: { path: ROUTES_PATH.HOME },
  [KEYBOARD_SHORTCUT_KEYS.NAVIGATE_INVESTMENT_PLANNER]: { path: ROUTES_PATH.INVESTMENT_PLANNER },
  [KEYBOARD_SHORTCUT_KEYS.NAVIGATE_RECIPIENTS]: { path: ROUTES_PATH.RECIPIENTS },
  [KEYBOARD_SHORTCUT_KEYS.NAVIGATE_REPORTS]: { path: ROUTES_PATH.REPORTS },
  [KEYBOARD_SHORTCUT_KEYS.NAVIGATE_TRANSACTIONS]: { path: ROUTES_PATH.TRANSACTIONS },
};

export const NAVIGATION_KEY_SHORTCUTS_MAP: Record<string, string> = {
  [ROUTE_KEYS.ACCOUNTS_V2]: KEYBOARD_SHORTCUT_KEYS.NAVIGATE_ACCOUNTS,
  [ROUTE_KEYS.CARDS]: KEYBOARD_SHORTCUT_KEYS.NAVIGATE_CARDS,
  [ROUTE_KEYS.HOME]: KEYBOARD_SHORTCUT_KEYS.NAVIGATE_HOME,
  [ROUTE_KEYS.INVESTMENT_PLANNER]: KEYBOARD_SHORTCUT_KEYS.NAVIGATE_INVESTMENT_PLANNER,
  [ROUTE_KEYS.RECIPIENTS]: KEYBOARD_SHORTCUT_KEYS.NAVIGATE_RECIPIENTS,
  [ROUTE_KEYS.REPORTS]: KEYBOARD_SHORTCUT_KEYS.NAVIGATE_REPORTS,
  [ROUTE_KEYS.TRANSACTIONS]: KEYBOARD_SHORTCUT_KEYS.NAVIGATE_TRANSACTIONS,
  [ROUTE_KEYS.TREASURY]: KEYBOARD_SHORTCUT_KEYS.NAVIGATE_TREASURY,
};
