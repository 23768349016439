import { defaultFnType } from 'destiny/dist/types';
import { TYPES_OF_LINK } from 'modules/tnc-pending/tncPending.constants';
import Link from 'next/link';
import { LinkType } from 'types/tncApi.types';
import { checkIsObjectEmpty } from 'utils/common';

/**
 * 
 * @param text e.g {{layer2_tnc}} Minimum Disclaimers
 * @param links e.g {
    "layer2_tnc": {
        "label": "Layer2",
        "link": "/terms-and-conditions?type=pending&id=fortress_trust_account_agreement",
        "type": "internal"
    }
}
 * @param handleLinkClick function to be called after link is clicked
 * @returns text with inserted links e.g:- Layer2 Minimum Disclaimers
 */
const insertLinkInText = (
  text: string,
  links: Record<string, LinkType>,
  handleLinkClick?: defaultFnType,
  linkClassName?: string
) => {
  if (checkIsObjectEmpty(links)) return text;

  let startKeyPlaceholder = text.indexOf('{{');

  const arr: string[] = [];

  if (startKeyPlaceholder >= 0) {
    do {
      const endKeyPlaceholder = text.indexOf('}}');
      const placeholder = text.substring(startKeyPlaceholder, endKeyPlaceholder + 2);

      arr.push(text.substring(0, startKeyPlaceholder));
      arr.push(placeholder);
      text = text.substring(endKeyPlaceholder + 2);
      startKeyPlaceholder = text.indexOf('{{');
    } while (startKeyPlaceholder >= 0);
    arr.push(text);
  } else arr.push(text);

  return arr.map((item) => {
    if (item.startsWith('{{')) {
      const keyName = item.substring(2, item?.length - 2);
      const value = links?.[keyName];

      switch (value?.type) {
        case TYPES_OF_LINK.EXTERNAL:
          return (
            <a
              href={value?.link}
              target='_blank'
              rel='noreferrer'
              key={keyName}
              className={`tw-text-ZAMP_PRIMARY tw-underline-none ${linkClassName}`}
              onClick={handleLinkClick}
            >
              {value?.label}
            </a>
          );
        case TYPES_OF_LINK.INTERNAL:
          return (
            <Link
              target='_blank'
              href={value?.link}
              rel='noopener noreferrer'
              className='tw-text-ZAMP_PRIMARY tw-underline-none'
              onClick={handleLinkClick}
              key={keyName}
            >
              {value?.label}
            </Link>
          );
        default:
          return value?.label;
      }
    } else return item;
  });
};

export default insertLinkInText;
