import { SCOPE_PERMISSION_RESOURCE_MAP } from 'constants/accessConstants';
import { ACCESS_HEADER_RESOURCE_ID_KEY, ACCESS_HEADER_RESOURCE_KEY } from 'constants/config';
import { store } from 'store';
import { MapAny } from 'types';
import { ACCESS_SCOPES, PERMISSION_NAMES, PermissionType } from 'types/access';

export const getUserAccessFromPermissions = (
  permissionId: PERMISSION_NAMES,
  scope: ACCESS_SCOPES,
  resourceId: string,
  userPermissions: PermissionType[] = []
) => {
  const hasAccess = !!userPermissions?.find(
    (permission: any) =>
      permission?.name === permissionId &&
      permission?.resources[SCOPE_PERMISSION_RESOURCE_MAP[scope]]?.includes(resourceId)
  );

  return hasAccess;
};

export const accessBasedQueryWrapper = (permission: PERMISSION_NAMES | null, params: MapAny) => {
  if (permission === null) return params;

  const { user, permissions } = store.getState().user;

  const hasAccess = getUserAccessFromPermissions(
    permission,
    params?.headers?.[ACCESS_HEADER_RESOURCE_KEY],
    params?.headers?.[ACCESS_HEADER_RESOURCE_ID_KEY] ?? user?.merchant_id,
    permissions
  );

  return hasAccess ? params : { forceAbort: true };
};

