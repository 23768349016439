import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { ACCESS_HEADER_RESOURCE_KEY, PLATFORM_IDEMPOTENCY_HEADER_KEY } from 'constants/config';
import { APITags, REQUEST_TYPES } from 'constants/index';
import baseApi from 'services/api';
import { ActivateProductRequest, GetPromptProductsResponse, GetPromptsResponse } from 'types/promptsApi.types';
import { accessBasedQueryWrapper } from 'utils/access';

const Prompt = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<GetPromptProductsResponse, void>({
      query: (intent) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.permissionId, {
          url: 'v1/prompt/products',
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.scope,
          },
          params: { intent },
        }),
      transformResponse: ({ data }) => data,
    }),
    getPrompt: builder.query<GetPromptsResponse, string>({
      query: (intent) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.permissionId, {
          url: 'v1/prompt',
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.scope,
          },
          params: { intent },
        }),
      transformResponse: ({ data }) => data,
      providesTags: [APITags.GET_PROMPT],
    }),
    activateProduct: builder.mutation<void, ActivateProductRequest>({
      query: ({ parent_type, parent_id, product_id, idempotencyHeader }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.permissionId, {
          url: 'v1/prompt/activate-product',
          method: REQUEST_TYPES.POST,
          body: { parent_type, parent_id, product_id },
          headers: {
            [PLATFORM_IDEMPOTENCY_HEADER_KEY]: idempotencyHeader,
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.scope,
          },
        }),
      transformResponse: ({ data }) => data,
      invalidatesTags: [
        APITags.GET_COMPANY_DETAILS,
        APITags.GET_PROMPT,
        APITags.GET_NAVIGATION,
        APITags.GET_MERCHANT_DETAILS,
      ],
    }),
  }),
});

export const { useGetProductsQuery, useGetPromptQuery, useActivateProductMutation } = Prompt;
