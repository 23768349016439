import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { ACCESS_HEADER_RESOURCE_KEY } from 'constants/config';
import { APITags, REQUEST_TYPES } from 'constants/index';
import baseApi from 'services/api';
import { AcceptPendingTnCPayload, PendingTnCResponse, TncType } from 'types/tncApi.types';
import { accessBasedQueryWrapper } from 'utils/access';

const Beneficiary = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTermsAndConditions: builder.query<TncType[], string>({
      query: (appName: string) => ({
        url: `v1/tnc?code=${appName}`,
      }),
      transformResponse: ({ data }) => data,
    }),
    getPendingTermsAndConditions: builder.query<PendingTnCResponse, void>({
      query: () => ({
        url: 'v1/tnc/pending',
      }),
      transformResponse: ({ data }) => data,
    }),
    acceptPendingTermsAndConditions: builder.mutation<void, AcceptPendingTnCPayload>({
      query: (data) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.permissionId, {
          url: 'v1/tnc/pending',
          method: REQUEST_TYPES.POST,
          body: data,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.scope,
          },
        }),
      transformResponse: ({ data }) => data,
      invalidatesTags: [APITags.GET_MERCHANT_DETAILS, APITags.GET_USER],
    }),
  }),
});

export const {
  useGetTermsAndConditionsQuery,
  useAcceptPendingTermsAndConditionsMutation,
  useGetPendingTermsAndConditionsQuery,
} = Beneficiary;
