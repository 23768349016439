import React, { FC, Fragment } from 'react';
import ErrorCard from 'components/banners/ErrorCard';
import { ErrorCardTypes } from 'components/banners/types';
import { Loader } from 'components/loader/Loader';
import NoPermissionState from 'components/NoPermissionState';
import SkeletonCard from 'components/skeletons/SkeletonCard';
import SkeletonComponent from 'components/skeletons/SkeletonComponent';
import SkeletonList from 'components/skeletons/SkeletonList';
import SkeletonTable from 'components/skeletons/SkeletonTable';
import { SkeletonTypes } from 'components/skeletons/types';
import { CommonWrapperPropsTypes } from 'components/wrappers/types';

const CommonWrapper: FC<CommonWrapperPropsTypes> = ({
  children,
  isNoData = false,
  isAccessBlocked = false,
  isLoading = false,
  isSuccess = false,
  isError = false,
  errorCardTitle,
  errorCardSubTitle = 'Something went wrong please try again...',
  height,
  errorCardType = ErrorCardTypes.API_FAIL,
  refetchFunnction,
  errorCardStyle = '',
  skeletonType = SkeletonTypes.DEFAULT,
  noDataBanner = null,
  successCard = null,
  loaderClassName = 'tw-h-32 tw-py-5',
  errorCardProps = {},
  skeleton,
  renderError,
  className = '',
  noPermissionsClassName = '',
  skeletonItemCount = 1,
}) => {
  const getSkeleton = () => {
    switch (skeletonType) {
      case SkeletonTypes.COMPONENT:
        return <SkeletonComponent height={height} />;
      case SkeletonTypes.LIST:
        return <SkeletonList height={height} />;
      case SkeletonTypes.CARD:
        return <SkeletonCard height={height} />;
      case SkeletonTypes.TABLE:
        return <SkeletonTable height={height} />;
      case SkeletonTypes.ASSET_TABLE:
        return <SkeletonTable height={height} isAsset />;
      case SkeletonTypes.CUSTOM:
        return skeleton;
      case SkeletonTypes.DEFAULT:
        return (
          <div className={`${loaderClassName} tw-flex tw-justify-center tw-items-center`} style={{ height }}>
            <Loader />
          </div>
        );
    }
  };

  if (isLoading)
    return (
      <div key='loader' className={`tw-animate-opacity ${className}`}>
        {new Array(skeletonItemCount).fill(0).map((_, index) => (
          <Fragment key={index}>{getSkeleton()}</Fragment>
        ))}
      </div>
    );

  if (isError)
    return renderError ? (
      <>{renderError}</>
    ) : (
      <ErrorCard
        height={height}
        type={errorCardType}
        onClose={() => refetchFunnction?.()}
        isLoading={isLoading}
        className={errorCardStyle}
        title={errorCardTitle}
        subtitle={errorCardSubTitle}
        {...errorCardProps}
      />
    );

  if (isSuccess && successCard)
    return (
      <div key='success-card' className='tw-animate-opacity'>
        {successCard}
      </div>
    );

  if (isAccessBlocked) return <NoPermissionState className={noPermissionsClassName} />;

  if (isNoData)
    return (
      <div key='zero-state' className={`tw-animate-opacity ${className}`}>
        {noDataBanner}
      </div>
    );

  return children ? (
    <div key='main-body' className={`tw-animate-opacity ${className}`}>
      {children}
    </div>
  ) : null;
};

export default CommonWrapper;
