import { LAUNCH_DARKLY_FLAGS } from 'constants/launchDarkly';

export const getVariation = (flag: LAUNCH_DARKLY_FLAGS, defaultValue = false) => {
  const ldClient = (window as any)?.ldclient;

  return ldClient?.variation(flag, defaultValue) ?? defaultValue;
};

export const setLDClientToWindow = (ldClient: any) => {
  (window as any).ldclient = ldClient;
};
