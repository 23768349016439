import { createSlice } from '@reduxjs/toolkit';
import { StatusType } from 'constants/kyb';
import { KybStatusResponse } from 'types/kybApi.types';

export type CounterState = {
  kybForm: any;
  kybFormStructure: Array<any>;
  kybStatus: StatusType;
  kybDetails: KybStatusResponse;
  bankingKybStatus: StatusType;
  treasuryKybStatus: StatusType;
  cardsKybStatus: StatusType;
  isBankingKybSuccess: boolean;
  isTreasuryKybSuccess: boolean;
  isCardsKybSuccess: boolean;
};

const initialState: CounterState = {
  kybForm: {},
  kybFormStructure: [],
  kybStatus: StatusType.NOT_INITIATED,
  kybDetails: {
    status: StatusType.NOT_INITIATED,
    product_kyb_status: {
      banking: StatusType.NOT_INITIATED,
      treasury: StatusType.NOT_INITIATED,
      cards: StatusType.NOT_INITIATED,
    },
  },
  bankingKybStatus: StatusType.NOT_INITIATED,
  treasuryKybStatus: StatusType.NOT_INITIATED,
  cardsKybStatus: StatusType.NOT_INITIATED,
  isBankingKybSuccess: false,
  isTreasuryKybSuccess: false,
  isCardsKybSuccess: false,
};

export const kybSlice = createSlice({
  name: 'kyb',
  initialState,
  reducers: {
    setForm: (state, action) => {
      state.kybForm = action.payload;
    },
    clearForm: (state) => {
      state.kybForm = {};
    },
    setSingleForm: (state, action) => {
      const { data, index } = action.payload;

      state.kybForm[index] = data;
    },
    setFormStructure: (state, action) => {
      state.kybFormStructure = action.payload;
    },
    setKybStatus: (state, action) => {
      state.kybStatus = action.payload;
    },
    setKybDetails: (state, action) => {
      state.kybDetails = action.payload;
      state.bankingKybStatus = action.payload?.product_kyb_status?.banking;
      state.treasuryKybStatus = action.payload?.product_kyb_status?.treasury;
      state.cardsKybStatus = action.payload?.product_kyb_status?.cards;
      state.isBankingKybSuccess = action.payload?.product_kyb_status?.banking === StatusType.SUCCESS;
      state.isTreasuryKybSuccess = action.payload?.product_kyb_status?.treasury === StatusType.SUCCESS;
      state.isCardsKybSuccess = action.payload?.product_kyb_status?.cards === StatusType.SUCCESS;
    },
  },
});

export const { setForm, clearForm, setFormStructure, setSingleForm, setKybStatus, setKybDetails } = kybSlice.actions;

export default kybSlice.reducer;
