import { OfferedProductsType, PRODUCT_TYPES } from 'types';

export const checkDependentProducts = (product: PRODUCT_TYPES, offeredProducts?: OfferedProductsType) => {
  const dependentProducts: PRODUCT_TYPES[] = [];

  if (offeredProducts) {
    const productKeys = Object.keys(offeredProducts) as PRODUCT_TYPES[];

    productKeys?.forEach((key) => {
      const value = offeredProducts[key];

      if (value?.depends_on?.includes(product)) {
        dependentProducts.push(key);
      }
    });
  }

  return dependentProducts;
};

export const getProductDetails = (product: PRODUCT_TYPES, offeredProducts?: OfferedProductsType) => {
  return offeredProducts?.[product];
};
