import React, { ReactNode } from 'react';
import { captureException } from '@sentry/browser';
import { ERROR_TOKENS } from 'constants/index';
import ErrorPage from 'components/ErrorPage';

class ErrorBoundary extends React.Component<{ children: ReactNode }, { hasError: boolean }> {
  constructor(props: any) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error: Error, errorInfo: any) {
    // You can use your own error logging service here
    captureException(error, errorInfo);
    console.log({ error, errorInfo });
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError)
      // You can render any custom fallback UI
      return (
        <ErrorPage
          errorCode={ERROR_TOKENS.CLIENT_SIDE_EXCEPTION}
          className='tw-h-screen'
          title='Something went wrong'
          description="We're fixing it. Meanwhile, please reload and try again."
        />
      );

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
