import { ReactNode } from 'react';
import { ROUTES_PATH } from 'constants/routeConfig';
import TnCPending from 'modules/tnc-pending/TnCPending';
import { UserAndMerchantStatusType } from 'types/api';

export const GLOBAL_BLOCKERS: Record<string, ReactNode> = {
  [UserAndMerchantStatusType.TNC_PENDING]: <TnCPending />,
};

export const GLOBAL_BLOCKERS_EXEMPTED_PAGES: Record<string, string[]> = {
  [UserAndMerchantStatusType.TNC_PENDING]: [ROUTES_PATH.TERMS_AND_CONDITIONS],
};
