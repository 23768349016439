import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { ACCESS_HEADER_RESOURCE_KEY } from 'constants/config';
import { APITags } from 'constants/index';
import baseApi from 'services/api';
import { WalletDetailsType } from 'types';
import { GetInvestmentSummaryResponseType } from 'types/api';
import {
  HomeAccountsResponseType,
  HomeAggregatedBalanceResponseType,
  HomeTreasuryHeroResponseType,
} from 'types/homeApi.types';
import { MerchantDetailsResponse } from 'types/merchantApi.types';
import { WalletAccountDetails } from 'types/romaAccounts';
import { accessBasedQueryWrapper } from 'utils/access';

const Home = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMerchantDetails: builder.query<MerchantDetailsResponse, void>({
      query: () => ({
        url: `v2/merchant/details/default`,
      }),
      transformResponse: ({ data }) => data,
      providesTags: [APITags.GET_MERCHANT_DETAILS],
    }),
    getBalances: builder.query<WalletDetailsType[], void>({
      query: () => ({
        url: `b2b-transfer/v1/balances`,
      }),
      transformResponse: ({ data }) => data,
    }),
    getInvestmentSummary: builder.query<GetInvestmentSummaryResponseType, void>({
      query: () =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.VIEW_TREASURY_INVESTMENTS.permissionId, {
          url: 'v1/investment/summary',
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.VIEW_TREASURY_INVESTMENTS.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    getAccounts: builder.query<WalletAccountDetails[], void>({
      query: () => ({
        url: `b2b-transfer/v1/accounts`,
      }),
      transformResponse: ({ data }) => data,
    }),
    getInternationalAccounts: builder.query<WalletAccountDetails, void>({
      query: () => ({
        url: `b2b-transfer/v1/international-accounts`,
      }),
      transformResponse: ({ data }) => data,
    }),
    getHomeAggregatedBalance: builder.query<HomeAggregatedBalanceResponseType, void>({
      query: () =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.VIEW_MERCHANT_ACCOUNTS.permissionId, {
          url: 'v1/home/banking-hero',
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.VIEW_MERCHANT_ACCOUNTS.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    getHomeAccounts: builder.query<HomeAccountsResponseType, void>({
      query: () =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.VIEW_MERCHANT_ACCOUNTS.permissionId, {
          url: 'v1/home/accounts',
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.VIEW_MERCHANT_ACCOUNTS.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    getHomeTreasuryHero: builder.query<HomeTreasuryHeroResponseType, void>({
      query: () =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.VIEW_MERCHANT_ACCOUNTS.permissionId, {
          url: 'v1/home/treasury-hero',
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.VIEW_MERCHANT_ACCOUNTS.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
  }),
});

export const {
  useGetMerchantDetailsQuery,
  useLazyGetMerchantDetailsQuery,
  useLazyGetBalancesQuery,
  useGetInvestmentSummaryQuery,
  useLazyGetInvestmentSummaryQuery,
  useGetAccountsQuery,
  useGetBalancesQuery,
  useGetInternationalAccountsQuery,
  useLazyGetHomeAggregatedBalanceQuery,
  useLazyGetHomeAccountsQuery,
  useLazyGetHomeTreasuryHeroQuery,
  useGetHomeTreasuryHeroQuery,
} = Home;
