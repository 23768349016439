import { FC } from 'react';
import { X_CLOSE } from 'constants/icons';
import { Text } from 'destiny/dist/components/atoms/text';
import { defaultFn } from 'destiny/dist/constants/index';
import Image from 'next/image';
import { defaultFnType } from 'types';

interface PopupHeaderProps {
  handleClose: defaultFnType;
  title?: string;
}

const PopupHeader: FC<PopupHeaderProps> = ({ handleClose = defaultFn, title = '' }) => (
  <div className='tw-flex tw-mb-8 tw-justify-between'>
    <Text textClass='tw-text-TEXT_PRIMARY f-20-500'>{title}</Text>
    <Image src={X_CLOSE} width={24} height={24} alt='close' onClick={handleClose} className='tw-cursor-pointer' />
  </div>
);

export default PopupHeader;
