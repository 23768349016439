import { createSlice } from '@reduxjs/toolkit';

export type TransactionState = {
  updateList: boolean;
};

const initialState: TransactionState = {
  updateList: false,
};

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setUpdateList: (state, action) => {
      state.updateList = action.payload;
    },
  },
});

export const { setUpdateList } = transactionsSlice.actions;

export default transactionsSlice.reducer;
