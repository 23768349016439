import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { ACCESS_HEADER_RESOURCE_KEY, PLATFORM_IDEMPOTENCY_HEADER_KEY } from 'constants/config';
import { APITags, REQUEST_TYPES } from 'constants/index';
import baseApi from 'services/api';
import { MapAny } from 'types';
import { KybSubmitPayloadWithId, KybSubmitResponse, SignedUrlBodyType } from 'types/api';
import { KybConfigResponse } from 'types/kyb';
import { KybStatusResponse } from 'types/kybApi.types';
import { accessBasedQueryWrapper } from 'utils/access';

const KYB = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getKYBConfig: builder.query<KybConfigResponse, string>({
      query: (country) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.permissionId, {
          url: `v1/form/config/${country ?? 'US'}`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    getKYBStatus: builder.query<KybStatusResponse, void>({
      query: () => ({
        url: 'v1/kyb/status',
      }),
      transformResponse: ({ data }) => data,
      providesTags: [APITags.GET_KYB],
    }),
    getSignedUrl: builder.mutation<any, SignedUrlBodyType>({
      query: (payload) => ({
        url: 'v1/form/signed-upload-url',
        method: REQUEST_TYPES.POST,
        body: payload,
      }),
      transformResponse: ({ data }) => data,
    }),
    submitKybForEntity: builder.mutation<KybSubmitResponse, KybSubmitPayloadWithId>({
      query: (payload) => {
        return accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.permissionId, {
          url: `v1/kyb/${payload.entityId}`,
          method: REQUEST_TYPES.POST,
          body: payload.data,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.scope,
            [PLATFORM_IDEMPOTENCY_HEADER_KEY]: payload.idempotencyHeader,
          },
        });
      },
      transformResponse: ({ data }) => data,
      invalidatesTags: (_, error) => {
        if (!error) return [APITags.GET_KYB];

        return [];
      },
    }),
    acknowledgeKYB: builder.mutation<void, void>({
      query: (payload) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.permissionId, {
          url: 'v1/kyb/acknowledge',
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.scope,
          },
          method: REQUEST_TYPES.PATCH,
          body: payload,
        }),
      transformResponse: ({ data }) => data,
      invalidatesTags: (_, error) => {
        if (!error) return [APITags.GET_KYB];

        return [];
      },
    }),
    getKYBDraft: builder.query<Record<string, string>, string>({
      query: (entityId) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.permissionId, {
          url: `v1/kyb/draft/${entityId}`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    updateKybDraft: builder.mutation<void, Record<string, MapAny>>({
      query: (payload) => {
        const url = `v1/kyb/draft/${payload.entityId}`;

        delete payload.entityId;

        return accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.permissionId, {
          url: url,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.scope,
          },
          method: REQUEST_TYPES.PATCH,
          body: payload,
        });
      },
    }),
  }),
});

export const {
  useGetKYBConfigQuery,
  useLazyGetKYBConfigQuery,
  useLazyGetKYBStatusQuery,
  useGetSignedUrlMutation,
  useSubmitKybForEntityMutation,
  useAcknowledgeKYBMutation,
  useGetKYBDraftQuery,
  useUpdateKybDraftMutation,
} = KYB;
