import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { ACCESS_HEADER_RESOURCE_KEY, MFA_TOTP_KEY, PLATFORM_IDEMPOTENCY_HEADER_KEY } from 'constants/config';
import { APITags, REQUEST_TYPES } from 'constants/index';
import { GetPoliciesResponse } from 'modules/policies/policiesApi.types';
import baseApi from 'services/api';
import {
  CompanyDetailsResponse,
  GetApprovalRequestsMinifiedResponseType,
  PatchMerchantIntentRequest,
  PatchTreasuryCashSweepConsentRequest,
  RolesByMerchantRequestParams,
  RolesByMerchantResponseType,
  RolesResourceFilteredByMerchantRequestParams,
  TreasuryCashSweepCardDetailsType,
} from 'types/merchantApi.types';
import { accessBasedQueryWrapper } from 'utils/access';

const Merchant = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    patchTreasuryCashSweepConsent: builder.mutation<void, PatchTreasuryCashSweepConsentRequest>({
      query: (payload) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_TREASURY_INVESTMENTS.permissionId, {
          url: `v2/merchant/treasury-cash-sweep-consent`,
          method: REQUEST_TYPES.PATCH,
          body: payload.data,
          headers: {
            [PLATFORM_IDEMPOTENCY_HEADER_KEY]: payload.idempotencyHeader,
            [MFA_TOTP_KEY]: payload.totp,
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_TREASURY_INVESTMENTS.scope,
          },
        }),
      invalidatesTags: [APITags.GET_MERCHANT_DETAILS],
    }),
    patchIntent: builder.mutation<void, PatchMerchantIntentRequest>({
      query: (payload) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.permissionId, {
          url: `v2/merchant/intent`,
          method: REQUEST_TYPES.PATCH,
          body: payload.data,
          headers: {
            [PLATFORM_IDEMPOTENCY_HEADER_KEY]: payload.idempotencyHeader,
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.scope,
          },
        }),
      invalidatesTags: [APITags.GET_MERCHANT_DETAILS, APITags.GET_NAVIGATION],
    }),
    getCashSweepDetails: builder.query<TreasuryCashSweepCardDetailsType[], void>({
      query: () => ({
        url: `v2/treasury/cash-sweep`,
      }),
      transformResponse: ({ data }) => data,
    }),
    getMerchantCompanyDetails: builder.query<CompanyDetailsResponse, void>({
      query: () =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.permissionId, {
          url: `v2/merchant/company-details`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.scope,
          },
        }),
      transformResponse: ({ data }) => data,
      providesTags: [APITags.GET_COMPANY_DETAILS],
    }),
    getRolesByMerchant: builder.query<RolesByMerchantResponseType[], RolesByMerchantRequestParams>({
      query: (params) =>
        accessBasedQueryWrapper(null, {
          url: `v1/merchants/roles`,
          params,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_TEAM.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    getPoliciesByMerchant: builder.query<GetPoliciesResponse[], void>({
      query: () =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_POLICIES.permissionId, {
          url: `v1/merchants/policies`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_POLICIES.scope,
          },
        }),
      transformResponse: ({ data }) => data,
      providesTags: [APITags.GET_POLICIES],
    }),
    getApprovalRequestsMinified: builder.query<GetApprovalRequestsMinifiedResponseType[], string>({
      query: (statuses) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.VIEW_TRANSACTIONS.permissionId, {
          url: `v1/banking/merchants/transactions/requests/minified?policy_evaluation_statuses=${statuses}`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.VIEW_TRANSACTIONS.scope,
          },
        }),
      transformResponse: ({ data }) => data,
      providesTags: [APITags.GET_PENDING_TRANSACTIONS],
    }),
    getRolesResourceFilteredByMerchant: builder.query<
      RolesByMerchantResponseType[],
      RolesResourceFilteredByMerchantRequestParams
    >({
      query: (params) =>
        accessBasedQueryWrapper(null, {
          url: `v1/merchants/roles-resource-filtered`,
          params,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_TEAM.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
  }),
});

export const {
  usePatchIntentMutation,
  usePatchTreasuryCashSweepConsentMutation,
  useGetCashSweepDetailsQuery,
  useGetMerchantCompanyDetailsQuery,
  useGetRolesByMerchantQuery,
  useGetPoliciesByMerchantQuery,
  useLazyGetApprovalRequestsMinifiedQuery,
  useGetRolesResourceFilteredByMerchantQuery,
} = Merchant;
