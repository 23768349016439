import { FC, ReactNode } from 'react';
import { CLOSE } from 'constants/icons';
import { Button } from 'destiny/dist/components/molecules/button';
import { BUTTON_SIZE_TYPES, BUTTON_TYPES } from 'destiny/dist/constants';
import Image from 'next/image';
import { trackMixpanel } from 'utils/mixpanel';

type ConfirmPopupProps = {
  isOpen: boolean;
  children: any;
  childrenClassName?: string;
  className?: string;
  parentWrapperClassName?: string;
  wrapperClassName?: string;
  titleClassName?: string;
  buttonClassName?: string;
  title: string;
  showClose?: boolean;
  cancelBtnText?: ReactNode;
  submitBtnText?: ReactNode;
  showCancel?: boolean;
  customButtons?: ReactNode;
  footer?: ReactNode;
  onCancel?: () => void;
  onSubmit?: () => void;
  mixpanelEventOnCancel?: string;
  mixpanelEventOnSubmit?: string;
  mixpanelEventOnClose?: string;
  isSubmitButtonLoading?: boolean;
  submitBtnStyle?: string;
  cancelBtnStyle?: string;
  isSubmitButtonDisabled?: boolean;
};

const ConfirmPopup: FC<ConfirmPopupProps> = ({
  isOpen = false,
  children,
  className = '',
  parentWrapperClassName = null,
  wrapperClassName = null,
  titleClassName = null,
  buttonClassName = null,
  childrenClassName = null,
  title,
  showClose = true,
  cancelBtnText = 'Cancel',
  submitBtnText = 'Ok',
  submitBtnStyle = '',
  isSubmitButtonLoading = false,
  showCancel = true,
  customButtons = null,
  footer = null,
  onCancel,
  onSubmit,
  mixpanelEventOnCancel,
  mixpanelEventOnClose,
  mixpanelEventOnSubmit,
  cancelBtnStyle = '',
  isSubmitButtonDisabled = false,
}) => {
  const handleCancelOnClick = () => {
    mixpanelEventOnCancel && trackMixpanel(mixpanelEventOnCancel, {});
    onCancel && onCancel();
  };
  const handleCloseOnClick = () => {
    mixpanelEventOnClose && trackMixpanel(mixpanelEventOnClose, {});
    onCancel && onCancel();
  };
  const handleSubmitOnClick = () => {
    mixpanelEventOnSubmit && trackMixpanel(mixpanelEventOnSubmit, {});
    onSubmit && onSubmit();
  };

  if (!isOpen) return null;

  return (
    <div
      className={`${
        parentWrapperClassName ?? 'tw-z-[100]'
      } tw-bg-BACKDROP_GREY tw-transition-all tw-duration-300 tw-ease-in tw-fixed tw-w-screen tw-h-screen tw-top-0 tw-left-0 ${
        isOpen ? 'tw-opacity-1' : 'tw-hidden tw-opacity-0'
      }`}
      role='presentation'
      onClick={handleCloseOnClick}
      data-testid='CONFIRM_POPUP'
    >
      <div className='tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center'>
        <div
          className={`${
            wrapperClassName ?? 'tw-w-[500px]'
          }  tw-bg-white tw-transition-all tw-duration-300 tw-ease-in tw-rounded-xl tw-block ${className} ${
            isOpen ? ' tw-translate-y-0 tw-opacity-1' : 'tw-translate-y-[50px] tw-opacity-0'
          }`}
          role='presentation'
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={`${titleClassName ?? 'tw-py-8 tw-px-12'} f-20-500 tw-flex tw-items-center tw-justify-between`}
          >
            <span data-testid='CONFIRM_POPUP_TITLE'>{title}</span>

            {showClose && (
              <Image
                src={CLOSE}
                alt='Close Icon'
                draggable='false'
                width={14}
                height={13}
                className='tw-cursor-pointer'
                role='presentation'
                onClick={handleCloseOnClick}
                priority={true}
              />
            )}
          </div>
          <div className={`${childrenClassName ?? 'tw-px-12'}`}>{children}</div>
          <div className={`${buttonClassName ?? 'tw-py-8 tw-px-12'} tw-flex tw-items-center tw-justify-end`}>
            {customButtons ? (
              customButtons
            ) : (
              <>
                {showCancel && (
                  <Button
                    buttonProps={{
                      btnType: BUTTON_TYPES.SECONDARY,
                      size: BUTTON_SIZE_TYPES.MEDIUM,
                      onClick: handleCancelOnClick,
                      wrapperClass: cancelBtnStyle,
                      id: 'CONFIRM_POPUP_CANCEL_BUTTON',
                      eventCallback: trackMixpanel,
                    }}
                  >
                    {cancelBtnText}
                  </Button>
                )}
                <Button
                  buttonProps={{
                    btnType: BUTTON_TYPES.PRIMARY,
                    size: BUTTON_SIZE_TYPES.MEDIUM,
                    onClick: handleSubmitOnClick,
                    wrapperClass: `tw-ml-2.5 ${submitBtnStyle}`,
                    isLoading: isSubmitButtonLoading,
                    disabled: isSubmitButtonDisabled,
                    id: 'CONFIRM_POPUP_CONFIRM_BUTTON',
                    eventCallback: trackMixpanel,
                  }}
                >
                  {submitBtnText}
                </Button>
              </>
            )}
          </div>
          {footer ?? null}
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
