import { FC, ReactElement } from 'react';
import useUserAccessFromPermission from 'hooks/useUserAccessFromPermission';
import { ACCESS_SCOPES, PERMISSION_NAMES } from 'types/access';
import NoPermissionState from 'components/NoPermissionState';
interface AccessWrapperProps {
  permissionId: PERMISSION_NAMES;
  children: ReactElement;
  scope: ACCESS_SCOPES;
  resourceId?: string;
  deniedComponentClassName?: string;
  hideDeniedComponent?: boolean;
  deniedComponent?: ReactElement;
  skipAccessCheck?: boolean;
}
const AccessWrapper: FC<AccessWrapperProps> = ({
  permissionId,
  scope,
  resourceId,
  deniedComponentClassName = 'tw-h-[calc(100vh-224px)]',
  hideDeniedComponent = false,
  deniedComponent = null,
  children,
  skipAccessCheck = false,
}) => {
  const hasAccess = useUserAccessFromPermission(permissionId, scope, resourceId);

  if (!hasAccess && !skipAccessCheck)
    return !hideDeniedComponent ? deniedComponent ?? <NoPermissionState className={deniedComponentClassName} /> : null;

  return children;
};

export default AccessWrapper;
