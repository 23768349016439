import { useEffect, useRef } from 'react';
import { KEYBOARD_SHORTCUT_KEYS, MONITOR_KEYS, SHORTCUT_PROPERTIES } from 'constants/shortcuts';
import { useAppDispatch, useAppSelector } from 'hooks/toolkit';
import { useRouter } from 'next/router';
import { RootState } from 'store';
import { clearShortcut, setShortcut } from 'store/slices/shortcuts';
import { setIsGodMode } from 'store/slices/user';
import { generateShortcutCombinationIdentifier } from 'utils/common';
import { getFromLocalStorage, LOCAL_STORAGE_KEYS, removeFromLocalStorage, setToLocalStorage } from 'utils/localstorage';

/**
 * 1. Handle all key pressed and maintain a state in redux store for currently active key combination.
 * 2. On each keypress check if current combination matches any listening combinations.
 * 3. If match? do action.
 * 4. Clear combination after 2 seconds of any key press - Shortcuts will only work if combination is pressed together within 2 seconds.
 */

interface keyboardShortCutParams {
  handleCustomKeyboardAction?: (payload: string[]) => void;
}

const useKeyboardShortcuts = async (params?: keyboardShortCutParams) => {
  const { handleCustomKeyboardAction } = params ?? {};
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { appliedShortcut, listeningShortcuts } = useAppSelector((state: RootState) => state?.shortcuts);
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    /**
     * Do default actions set for specific keys.
     * Check if current key combination is part of listeners.
     * @param value - array of keys pressed
     * @returns void
     */
    const handleKeyboardAction = async (value: string[]) => {
      handleCustomKeyboardAction && handleCustomKeyboardAction?.(value);
      const keyboardEnumValue = generateShortcutCombinationIdentifier(value);

      if (!listeningShortcuts?.includes(keyboardEnumValue)) return;

      switch (keyboardEnumValue) {
        case KEYBOARD_SHORTCUT_KEYS.NAVIGATE_ACCOUNTS:
        case KEYBOARD_SHORTCUT_KEYS.NAVIGATE_CARDS:
        case KEYBOARD_SHORTCUT_KEYS.NAVIGATE_HOME:
        case KEYBOARD_SHORTCUT_KEYS.NAVIGATE_INVESTMENT_PLANNER:
        case KEYBOARD_SHORTCUT_KEYS.NAVIGATE_RECIPIENTS:
        case KEYBOARD_SHORTCUT_KEYS.NAVIGATE_REPORTS:
        case KEYBOARD_SHORTCUT_KEYS.NAVIGATE_TRANSACTIONS:
        case KEYBOARD_SHORTCUT_KEYS.NAVIGATE_TREASURY: {
          const properties = SHORTCUT_PROPERTIES[keyboardEnumValue];

          properties && router.push(properties?.path);
          break;
        }
        case KEYBOARD_SHORTCUT_KEYS.GOD_MODE:
          if (!getFromLocalStorage(LOCAL_STORAGE_KEYS.XZAMP_GOD_MODE)) {
            setToLocalStorage(LOCAL_STORAGE_KEYS.XZAMP_GOD_MODE, 'true');
            dispatch(setIsGodMode(true));
          } else {
            removeFromLocalStorage(LOCAL_STORAGE_KEYS.XZAMP_GOD_MODE);
            dispatch(setIsGodMode(false));
          }
          break;
      }
    };

    /**
     * Handle keypress
     * 1. Add current key to pressed keys array in store
     * 2. Set timer to clear all pressed keys after 2 seconds
     * @param event - Keyboard event
     */
    const handleKeyDown = async (event: KeyboardEvent) => {
      const ignoreTags = ['INPUT', 'TEXTAREA'];

      if (
        !ignoreTags.includes((event?.target as HTMLElement)?.tagName?.toUpperCase()) &&
        !event.repeat &&
        MONITOR_KEYS.includes(event.code)
      ) {
        const value = [...appliedShortcut];

        value.push(event.code);

        dispatch(setShortcut(value));

        handleKeyboardAction(value);

        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
          dispatch(clearShortcut());
        }, 1000);
      }

      return true;
    };

    /**
     * Remove key from pressed keys array in store
     * @param event - Keyboard eveny
     */
    // const handleKeyUp = async (event: KeyboardEvent) => {
    //   const ignoreTags = ['INPUT', 'TEXTAREA'];

    //   if (!ignoreTags.includes((event?.target as HTMLElement)?.tagName?.toUpperCase()) && !event.repeat) {
    //     const value = new Set(appliedShortcut);

    //     value.delete(event.code);

    //     dispatch(setShortcut(Array.from(value)));
    //   }

    //   return true;
    // };

    window?.addEventListener('keydown', handleKeyDown, { passive: true });
    // window?.addEventListener('keyup', handleKeyUp, { passive: true });

    return () => {
      window?.removeEventListener('keydown', handleKeyDown);
      // window?.removeEventListener('keyup', handleKeyUp);
    };
  }, [appliedShortcut, listeningShortcuts]);
};

export default useKeyboardShortcuts;
