import { useLazySignOutQuery } from 'api/authApi';
import { ROUTES_PATH } from 'constants/routeConfig';
import { useAppDispatch } from 'hooks/toolkit';
import { useRouter } from 'next/router';
import baseApi from 'services/api';
import { resetUser } from 'store/slices/user';

export const useLogout = () => {
  const [signOut] = useLazySignOutQuery();
  const router = useRouter();
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    signOut()
      .then(() => {
        if (window) window.location.href = ROUTES_PATH.LOGIN;
        else {
          dispatch(baseApi?.util?.resetApiState());
          dispatch(resetUser());
          router.push(ROUTES_PATH.LOGIN);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return handleLogout;
};
