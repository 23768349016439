import { APITags, REQUEST_TYPES } from 'constants/index';
import baseApi from 'services/api';
import {
  ResendOtpBodyType,
  ResendOtpResponseType,
  SignInBodyType,
  SignInResponseType,
  SignUpBodyType,
  SignUpResponseType,
  ValidateSignInBodyType,
  ValidateSignInResponseType,
  ValidateSignInV2BodyType,
  ValidateSignUpBodyType,
} from 'types/api';

const Authentication = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    signUp: builder.mutation<SignUpResponseType, SignUpBodyType>({
      query: (payload) => ({
        url: 'v1/b2b-user/sign-up',
        method: REQUEST_TYPES.POST,
        body: payload,
      }),
      transformResponse: ({ data }) => data,
    }),
    validateSignUp: builder.mutation<ValidateSignInResponseType, ValidateSignUpBodyType>({
      query: (payload) => ({
        url: 'v1/b2b-user/validate/sign-up',
        method: REQUEST_TYPES.POST,
        body: payload,
      }),
      transformResponse: ({ data }) => data,
      invalidatesTags: (data, error) => {
        if (!error) return [APITags.GET_USER];

        return [];
      },
    }),
    signIn: builder.mutation<SignInResponseType, SignInBodyType>({
      query: (payload) => ({
        url: 'v1/b2b-user/sign-in',
        method: REQUEST_TYPES.POST,
        body: payload,
      }),
      transformResponse: ({ data }) => data,
    }),
    validateSignIn: builder.mutation<ValidateSignInResponseType, ValidateSignInBodyType>({
      query: (payload) => ({
        url: 'v1/b2b-user/validate/sign-in',
        method: REQUEST_TYPES.POST,
        body: payload,
      }),
      transformResponse: ({ data }) => data,
      invalidatesTags: (_, error) => {
        if (!error) return [APITags.GET_USER];

        return [];
      },
    }),
    validateSignInV2: builder.mutation<ValidateSignInResponseType, ValidateSignInV2BodyType>({
      query: (payload) => ({
        url: 'v2/b2b-user/validate/sign-in',
        method: REQUEST_TYPES.POST,
        body: payload,
      }),
      transformResponse: ({ data }) => data,
      invalidatesTags: (_, error) => {
        if (!error) return [APITags.GET_USER];

        return [];
      },
    }),
    resendOtp: builder.mutation<ResendOtpResponseType, ResendOtpBodyType>({
      query: (payload) => ({
        url: 'v1/b2b-user/resend-otp',
        method: REQUEST_TYPES.POST,
        body: payload,
      }),
    }),
    signOut: builder.query<any, void>({
      query: () => 'v1/b2b-user/sign-out',
      transformResponse: ({ data }) => data,
    }),
  }),
});

export const {
  useSignUpMutation,
  useSignInMutation,
  useValidateSignUpMutation,
  useValidateSignInMutation,
  useValidateSignInV2Mutation,
  useResendOtpMutation,
  useLazySignOutQuery,
} = Authentication;
