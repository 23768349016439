import { ERROR_IMAGE } from 'constants/icons';
import Image from 'next/image';

const ZeroState = ({
  icon = ERROR_IMAGE,
  title = "We're sorry, the system is currently unavailable.",
  description = "We're fixing it and will notify you once it is available.",
  className = '',
  titleClassName = '',
  descriptionClassName = '',
}) => (
  <div className={`tw-text-center ${className}`}>
    <Image src={icon} alt='Logout' width={100} height={100} className='tw-m-auto' />
    <p className={`tw-m-0 tw-mt-4 tw-text-xl ${titleClassName}`}>{title}</p>
    <p className={`tw-m-0 tw-mt-4 tw-text-base tw-font-light ${descriptionClassName}`}>{description}</p>
  </div>
);

export default ZeroState;
