import React, { useEffect } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import { useGetCurrencyAndNetworkConfigsQuery } from 'api/config';
import { ACCESS_FLAGS } from 'constants/config';
import { GLOBAL_BLOCKERS, GLOBAL_BLOCKERS_EXEMPTED_PAGES } from 'constants/globalBlockers';
import { PUBLIC_ROUTES } from 'constants/routeConfig';
import { useAppDispatch, useAppSelector } from 'hooks/toolkit';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import IntentPage from 'modules/intent';
import ScreenSupport from 'modules/zero-state/ScreenSupport';
import { useRouter } from 'next/router';
import { RootState } from 'store';
import { setCurrenciesAndNetworks } from 'store/slices/config';
import { checkScreenBreakpoint } from 'utils/common';
import FullScreenPopup from 'components/popup/FullScreenPopup';

interface RoutePropsType {
  children: any;
}

const RouteGuard: React.FC<RoutePropsType> = ({ children = null }) => {
  const { width, height } = useWindowDimensions();
  const breakpoint = checkScreenBreakpoint(width, height);

  const { currencies, networks } = useAppSelector((state: RootState) => state.config);
  const router = useRouter();

  const user = useAppSelector((state: RootState) => state.user);
  const { userAccessFlags } = useAppSelector((state: RootState) => state.user);

  const isPublicRoute = !!PUBLIC_ROUTES[router.pathname as keyof typeof PUBLIC_ROUTES];
  const globalBlockerStatus: string =
    (Object.keys(GLOBAL_BLOCKERS)?.includes(user?.merchantDetails?.status) ? user?.merchantDetails?.status : '') ||
    (Object.keys(GLOBAL_BLOCKERS)?.includes(user?.user?.status) ? user?.user?.status : '');

  const { data: currencyAndNetworkConfigs } = useGetCurrencyAndNetworkConfigsQuery(undefined, {
    skip: (currencies?.length !== 0 && networks?.length !== 0) || !user?.user?.id,
  });

  const isPayoutsEnabled = userAccessFlags?.[ACCESS_FLAGS.IS_PAYOUTS_ENABLED];

  const dispatch = useAppDispatch();

  useEffect(() => {
    currencyAndNetworkConfigs && dispatch(setCurrenciesAndNetworks(currencyAndNetworkConfigs));
  }, [currencyAndNetworkConfigs, isPayoutsEnabled]);

  if (breakpoint) return <ScreenSupport />;

  if (!!user?.user?.id && user?.merchantDetails?.is_product_intent_required && !isPublicRoute) {
    return <IntentPage />;
  }

  // TODO: need to refactor to get conditions for global blocker in a better way
  return (
    <>
      <ToastContainer
        position='top-center'
        autoClose={2000}
        transition={Slide}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
        icon={false}
        closeButton={false}
      />
      {(isPublicRoute || globalBlockerStatus !== undefined) && (
        <>
          {!GLOBAL_BLOCKERS_EXEMPTED_PAGES[globalBlockerStatus]?.includes(router?.pathname) &&
          Object.keys(GLOBAL_BLOCKERS)?.includes(globalBlockerStatus) ? (
            <FullScreenPopup showCloseIcon={false} bodyClassName='tw-h-full tw-justify-center'>
              {GLOBAL_BLOCKERS[globalBlockerStatus]}
            </FullScreenPopup>
          ) : (
            children
          )}
        </>
      )}
    </>
  );
};

export default RouteGuard;
