import { Dispatch, type FC, SetStateAction } from 'react';
import { MenuOption } from 'destiny/dist/components/molecules/menuOption';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { Dropdown } from 'destiny/dist/components/organisms/dropdown';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { DROPDOWN_SIZE_TYPES, OptionsType } from 'destiny/dist/types/dropdown';
import { MONTHLY_TRADE_VOLUMES } from 'modules/intent/intent.constants';
import { OnOffRampConfig } from 'modules/intent/intent.types';
import { trackMixpanel } from 'utils/mixpanel';

type OnOffRampConfigSelectorType = {
  currencies: string[];
  title: string;
  id: string;
  config: OnOffRampConfig;
  setConfig: Dispatch<SetStateAction<OnOffRampConfig>>;
  isSelected: boolean;
  setIsSelected: Dispatch<SetStateAction<boolean>>;
};

const OnOffRampConfigSelector: FC<OnOffRampConfigSelectorType> = ({
  currencies,
  title,
  id,
  config,
  setConfig,
  isSelected = false,
  setIsSelected,
}) => {
  const handleMonthlyVolumeChange = (option: OptionsType) =>
    setConfig((prev) => ({ ...prev, monthlyTradeVolumes: option }));

  const handleCurrencyChange = (currency: string) =>
    setConfig((prev) => ({
      ...prev,
      currencies: prev?.currencies?.includes(currency)
        ? prev?.currencies?.filter((item) => item !== currency)
        : [...(prev?.currencies ?? []), currency],
    }));

  return (
    <div className='tw-border tw-border-TEXT_TERTIARY tw-space-y-6 tw-bg-white tw-rounded-2.5'>
      <MenuOption
        eventCallback={trackMixpanel}
        label={title}
        isMulti
        innerProps={{ id: `${id}_RAMP_CURRENCY_CHECKBOX` }}
        labelOverrideClassName='f-13-500 tw-uppercase'
        wrapperClass='tw-flex tw-items-center'
        isSelected={isSelected}
        onClick={() => setIsSelected((prev) => !prev)}
        containerClass=''
      />
      {isSelected && (
        <div className='tw-px-4 tw-pb-4 tw-space-y-6'>
          <div className='tw-flex tw-gap-4'>
            {currencies.map((item) => (
              <div
                className={`tw-rounded-2.5  tw-p-4 tw-flex tw-gap-2.5 tw-w-[130px] tw-cursor-pointer tw-shadow-midShadow ${
                  config?.currencies?.includes(item) ? 'tw-border-2 tw-border-GREEN_SECONDARY' : 'tw-border'
                }`}
                key={item}
                onClick={() => handleCurrencyChange(item)}
              >
                <SvgSpriteLoader id={item} iconCategory={ICON_SPRITE_TYPES.FIAT_CURRENCIES} width={16} height={16} />
                <div className='f-13-500'>{item}</div>
              </div>
            ))}
          </div>
          <Dropdown
            options={MONTHLY_TRADE_VOLUMES}
            id={`${id}_RAMP_MONTHLY_TRADE_VOLUMES_INTENT_DROPDOWN`}
            eventCallback={trackMixpanel}
            size={DROPDOWN_SIZE_TYPES.SMALL}
            placeholder='Select range'
            labelProps={{ description: 'Monthly trade volume', descriptionClass: 'f-14-400 tw-text-TEXT_SECONDARY' }}
            showLabel
            defaultValue={config?.monthlyTradeVolumes}
            onChange={handleMonthlyVolumeChange}
          />
        </div>
      )}
    </div>
  );
};

export default OnOffRampConfigSelector;
