import { MIX_PANEL_TEST, MIX_PANEL_TOKEN } from 'constants/config';
import mixpanelEvents from 'constants/mixpanel';
import mixpanel from 'mixpanel-browser';
import { store } from 'store';
import { MapAny } from 'types';

export function trackMixpanel(mixpanelEvent: string, payload: MapAny = {}) {
  console.log(mixpanelEvent, payload?.id ?? null);

  const {
    user: {
      user: { id: user_id = undefined, merchant_id = undefined, email: userEmail = null, role: user_role = null } = {},
      userAccessFlags = {},
      configuration,
    },
    kyb: { kybStatus: kyb_status = undefined } = {},
  } = store?.getState() || {};

  const domain = userEmail?.split('@')[1];

  const mixpanelPayload = {
    ...payload,
    user_id,
    user_role,
    merchant_id,
    kyb_status,
    ...userAccessFlags,
    merchant_config: configuration,
    page: window?.location?.pathname,
    domain,
  };

  try {
    switch (mixpanelEvent) {
      case mixpanelEvents.INITIALIZE:
        mixpanel.init(MIX_PANEL_TOKEN, { debug: MIX_PANEL_TEST, ignore_dnt: true });
        break;

      case mixpanelEvents.RESET:
        mixpanel.reset();
        break;

      case mixpanelEvents.IDENTIFY:
        mixpanel.identify(payload.id);
        mixpanel.people.set({ domain: payload?.email?.split('@')[1] });
        break;

      case mixpanelEvents.ALIAS:
        mixpanel.alias(payload.id);
        break;

      case mixpanelEvents.SET_USER_PROPERTIES:
        mixpanel.people.set_once({
          $first_name: payload.first_name,
          $last_name: payload.last_name,
          $created: payload.created_at,
          'Merchant Name': payload?.merchant_name,
        });
        break;

      default:
        mixpanel.track(mixpanelEvent, mixpanelPayload);
        break;
    }
  } catch (err) {
    return false;
  }
}
