import { useIsSuperUser } from 'hooks';

const SuperUserRibbon = () => {
  const isSuperUser = useIsSuperUser();

  if (!isSuperUser) return null;

  return (
    <div className='tw-fixed -tw-rotate-45 tw-bottom-10 -tw-right-10 tw-z-50 tw-pointer-events-none tw-bg-GREEN_PRIMARY tw-text-white f-12-300 tw-flex tw-justify-center tw-w-[200px] tw-py-1 tw-pb-1'>
      Super User
    </div>
  );
};

export default SuperUserRibbon;
