import { type FC } from 'react';
import { defaultFn } from 'destiny/dist/constants';
import { LinkType } from 'types/tncApi.types';
import insertLinkInText from 'utils/insertLinkInText';

export type NestedBulletListProps = {
  text: string;
  points?: NestedBulletListProps[];
};

const NestedBulletList: FC<{
  points: NestedBulletListProps[];
  links?: Record<string, LinkType>;
  className?: string;
  linkClassName?: string;
}> = ({ points, links = {}, className = '', linkClassName }) => (
  <ul className={`tw-list-disc tw-ml-4 ${className}`}>
    {points?.map((point, index) => (
      <li key={`point-${index}`}>
        {insertLinkInText(point.text, links, defaultFn, linkClassName)}
        {point?.points && <NestedBulletList points={point.points} links={links} />}
      </li>
    ))}
  </ul>
);

export default NestedBulletList;
