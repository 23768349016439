import { createSlice } from '@reduxjs/toolkit';

export type CounterState = {
  appliedShortcut: string[];
  listeningShortcuts: string[];
};

const initialState: CounterState = {
  appliedShortcut: [],
  listeningShortcuts: [],
};

export const shortcutsSlice = createSlice({
  name: 'shortcuts',
  initialState,
  reducers: {
    setShortcut: (state, action) => {
      state.appliedShortcut = action.payload;
    },
    addShortcutListeners: (state, action) => {
      const updated = new Set(state.listeningShortcuts);

      action?.payload?.forEach((element: string) => {
        updated.add(element);
      });

      state.listeningShortcuts = Array.from(updated);
    },
    deleteShortcutListeners: (state, action) => {
      const updated = new Set(state.listeningShortcuts);

      action?.payload?.forEach((element: string) => {
        updated.delete(element);
      });

      state.listeningShortcuts = Array.from(updated);
    },
    clearShortcut: (state) => {
      state.appliedShortcut = [];
    },
  },
});

export const { setShortcut, clearShortcut, addShortcutListeners, deleteShortcutListeners } = shortcutsSlice.actions;

export default shortcutsSlice.reducer;
