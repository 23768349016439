export enum SkeletonTypes {
  DEFAULT,
  LIST,
  COMPONENT,
  CARD,
  TABLE,
  ASSET_TABLE,
  CUSTOM,
}

export const SKELETON_ELEMENT_SHAPES = {
  CIRCLE: 'CIRCLE',
};
