import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  BaseQueryApi,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/react';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { captureException } from '@sentry/browser';
import { Mutex } from 'async-mutex';
import {
  API_DOMAIN,
  API_DOMAIN_ROMA,
  API_DOMAIN_ZAMP_INVEST,
  CSRF_TOKEN_KEY,
  PLATFORM_HEADER_KEY,
  PLATFORM_ONE_DASHBOARD_VALUE,
  REQUEST_TIMEOUT,
  ROMA_HOST,
  ZAMP_INVEST_HOST,
} from 'constants/config';
import { API_TAGS, CANARY_HEADER_KEY, ERROR_TOKENS, SESSION_EXPIRY_TOKENS } from 'constants/index';
import { LAUNCH_DARKLY_FLAGS } from 'constants/launchDarkly';
import { LOGIN_URLS, ROUTE_KEYS } from 'constants/routeConfig';
import { setUserSessionExpired } from 'store/slices/user';
import { MapAny } from 'types';
import { getCookieByName } from 'utils/http';
import { getVariation } from 'utils/launchDarkly';
import { getFromLocalStorage, LOCAL_STORAGE_KEYS } from 'utils/localstorage';

const mutex = new Mutex();

const dynamicBaseQuery: (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: NonNullable<unknown>
) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>> = (args, api, extraOptions) => {
  const isRomaGlobal = window?.location?.hostname?.endsWith(ROMA_HOST);
  const isZampInvest = window?.location?.hostname?.endsWith(ZAMP_INVEST_HOST);

  let baseUrl = API_DOMAIN;

  if (isRomaGlobal && API_DOMAIN_ROMA) {
    baseUrl = API_DOMAIN_ROMA;
  }
  if (isZampInvest && API_DOMAIN_ZAMP_INVEST) {
    baseUrl = API_DOMAIN_ZAMP_INVEST;
  }

  const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders(headers) {
      const isCanary = getVariation(LAUNCH_DARKLY_FLAGS.CANARY);
      const godMode = getFromLocalStorage(LOCAL_STORAGE_KEYS.XZAMP_GOD_MODE);

      if (isCanary && godMode) {
        headers.set(CANARY_HEADER_KEY, 'true');
      }

      headers.set(PLATFORM_HEADER_KEY, PLATFORM_ONE_DASHBOARD_VALUE);
      headers.set(CSRF_TOKEN_KEY, getCookieByName(CSRF_TOKEN_KEY));

      return headers;
    },
    credentials: 'include',
    timeout: REQUEST_TIMEOUT,
  });

  return baseQuery(args, api, extraOptions);
};

const baseQueryWithReauth: any = async (args: any, api: any, extraOptions: MapAny) => {
  await mutex.waitForUnlock();

  if (args.forceAbort) {
    captureException(new Error(ERROR_TOKENS.CLIENT_INVALID_API_CALL), {
      extra: {
        fullUrl: args.url,
        rtkEndpoint: api?.endpoint,
      },
    });
    console.log(ERROR_TOKENS.CLIENT_INVALID_API_CALL);

    api.abort();
  }

  const result = await dynamicBaseQuery(args, api, extraOptions);
  const path = window.location.pathname;

  const isLoginRoute = !!LOGIN_URLS.includes(path as keyof typeof ROUTE_KEYS);

  const error = result?.error;

  if (error) {
    const status = error?.status;
    const data: any = error?.data;

    if (status === 401 && SESSION_EXPIRY_TOKENS?.indexOf(data?.error?.code) >= 0 && !isLoginRoute) {
      const userId = api?.getState()?.user?.user?.id;

      if (userId) api?.dispatch(setUserSessionExpired(true));
    }
    if (status !== 401) {
      const errorObj = new Error(JSON.stringify(`${status}=${data?.error?.code ?? 'NA'}`));

      captureException(errorObj, {
        extra: {
          error: JSON.stringify(error),
          fullUrl: args.url,
          rtkEndpoint: api?.endpoint,
        },
      });
    }
  }

  return result;
};

const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: API_TAGS,
  refetchOnMountOrArgChange: true,
});

export default baseApi;
