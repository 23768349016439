import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CodeMap, CurrencyAndNetworkConfigs, NetworkTypes } from 'types/config';

interface ConfigState extends CurrencyAndNetworkConfigs {
  networkTypes: NetworkTypes[];
  networkCodeAndNameMap: CodeMap;
  networkCodeAndIconMap: CodeMap;
  currencyCodeAndIconMap: CodeMap;
  currencyCodeAndNameMap: CodeMap;
  currencyCodeAndNetworkCodeMap: CodeMap;
}

const initialState: ConfigState = {
  currencies: [],
  networks: [],
  networkTypes: [],
  networkCodeAndNameMap: {},
  networkCodeAndIconMap: {},
  currencyCodeAndIconMap: {},
  currencyCodeAndNameMap: {},
  currencyCodeAndNetworkCodeMap: {},
};

export const configsSlice = createSlice({
  name: 'configs',
  initialState,
  reducers: {
    setCurrenciesAndNetworks: (state, action: PayloadAction<CurrencyAndNetworkConfigs>) => {
      const { currencies = [], networks = [] } = action.payload;
      const networkTypes: NetworkTypes[] = [];
      const networkCodeAndNameMap: CodeMap = {};
      const networkCodeAndIconMap: CodeMap = {};
      const currencyCodeAndIconMap: CodeMap = {};
      const currencyCodeAndNameMap: CodeMap = {};
      const currencyCodeAndNetworkCodeMap: CodeMap = {};

      networks.forEach((network) => {
        networkTypes.push({ label: network.name, value: network.code, icon: network.logo });
        networkCodeAndNameMap[network.code] = network.name;
        networkCodeAndIconMap[network.code] = network.logo;
      });
      currencies.forEach((currency) => {
        currencyCodeAndNameMap[currency.code] = currency.name;
        currencyCodeAndIconMap[currency.code] = currency.logo;
        currencyCodeAndNetworkCodeMap[currency.code] = currency.network_code;
      });
      state.networkTypes = networkTypes;
      state.networkCodeAndNameMap = networkCodeAndNameMap;
      state.networkCodeAndIconMap = networkCodeAndIconMap;
      state.currencyCodeAndNameMap = currencyCodeAndNameMap;
      state.currencyCodeAndIconMap = currencyCodeAndIconMap;
      state.currencyCodeAndNetworkCodeMap = currencyCodeAndNetworkCodeMap;
      state.currencies = currencies;
      state.networks = networks;
    },
  },
});

export const { setCurrenciesAndNetworks } = configsSlice.actions;
export default configsSlice.reducer;
