import { FC } from 'react';
import { ESCAPE, ROMA_LOGO, ROMA_LOGO_GREEN, ZAMP_LOGO } from 'constants/icons';
import { LAUNCH_DARKLY_FLAGS } from 'constants/launchDarkly';
import { defaultFn } from 'destiny/dist/constants/index';
import Image from 'next/image';
import { defaultFnType } from 'types';
import { isRomaDashboard } from 'utils/common';
import { getVariation } from 'utils/launchDarkly';

interface FullScreenPopupProps {
  onClose?: defaultFnType;
  showCloseIcon?: boolean;
  showLogo?: boolean;
  className?: string;
  bodyClassName?: string;
  logoWrapperClassName?: string;
  logoDimensions?: { width?: number; height?: number };
  closeWrapperClassName?: string;
  closeIconSrc?: string;
  closeIconDimensions?: { width?: number; height?: number };
  children?: any;
}

const FullScreenPopup: FC<FullScreenPopupProps> = ({
  onClose = defaultFn,
  showCloseIcon = true,
  showLogo = true,
  className = '',
  bodyClassName = '',
  logoWrapperClassName = 'tw-w-[119.1px] tw-h-[29px] tw-absolute tw-top-[50px] tw-left-[51px]',
  logoDimensions = { width: 119.1, height: 29 },
  closeWrapperClassName = 'tw-flex tw-w-9 tw-h-[65px] tw-fixed tw-top-[42px] tw-right-11',
  closeIconSrc = ESCAPE,
  closeIconDimensions = { width: 36, height: 65 },
  children = null,
}) => {
  const isRoma = isRomaDashboard();
  const isOtcEnabled = getVariation(LAUNCH_DARKLY_FLAGS.OTC);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`tw-h-screen tw-w-screen tw-fixed tw-top-0 tw-left-0 tw-overflow-y-auto tw-bg-white tw-z-[150] ${className}`}
    >
      {showLogo && (
        <div className={logoWrapperClassName} role='presentation' onClick={onClose}>
          <Image
            src={isRoma ? (isOtcEnabled ? ROMA_LOGO_GREEN : ROMA_LOGO) : ZAMP_LOGO}
            alt='logo'
            {...logoDimensions}
          />
        </div>
      )}

      {showCloseIcon && (
        <div className={`tw-cursor-pointer ${closeWrapperClassName}`} role='presentation' onClick={onClose}>
          <Image priority={true} src={closeIconSrc} alt='Close Icon' {...closeIconDimensions} role='presentation' />
        </div>
      )}
      <div className={`tw-overflow-y-auto tw-w-full tw-flex tw-flex-col tw-items-center ${bodyClassName}`}>
        {children}
      </div>
    </div>
  );
};

export default FullScreenPopup;
