import { useEffect, useState } from 'react';
import { Button } from 'destiny/dist/components/molecules/button';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { defaultFn } from 'destiny/dist/constants';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { BUTTON_SIZE_TYPES, BUTTON_TYPES } from 'destiny/dist/constants/molecules/buttons';
import { defaultFnType } from 'types';
import { trackMixpanel } from 'utils/mixpanel';
import AddEntityPopup from 'components/add-entity/AddEntityPopup';

interface ChangeCountryProps {
  onClose?: defaultFnType;
  text?: string;
  showPopupExternally?: boolean;
  className?: string;
  showUI?: boolean;
  refreshMerchant?: boolean;
}

const AddEntity: React.FC<ChangeCountryProps> = ({
  onClose = defaultFn,
  text = 'Add company',
  showPopupExternally = false,
  className = '',
  showUI = false,
  refreshMerchant = false,
}) => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setShowPopup(showPopupExternally);
  }, [showPopupExternally]);

  const handleClose = () => {
    setShowPopup(false);
    onClose?.();
  };

  return (
    <span className={className}>
      {showUI && (
        <Button
          buttonProps={{
            size: BUTTON_SIZE_TYPES.SMALL,
            btnType: BUTTON_TYPES.TEXT_NAV,
            id: 'COMPANY_DETAILS_ADD_ENTITY',
            eventCallback: trackMixpanel,
            wrapperClass: 'hover:tw-no-underline !tw-p-4 tw-border tw-w-full tw-rounded-xl tw-h-auto',
            onClick: () => setShowPopup(true),
          }}
        >
          <span className='tw-flex tw-gap-2 tw-text-TEXT_PRIMARY hover:tw-no-underline'>
            <SvgSpriteLoader id='plus' iconCategory={ICON_SPRITE_TYPES.GENERAL} width={16} height={16} />
            {text}
          </span>
        </Button>
      )}
      <AddEntityPopup isOpen={showPopup} onClose={handleClose} refreshMerchant={refreshMerchant} />
    </span>
  );
};

export default AddEntity;
