import { FC } from 'react';

interface SkeletonProps {
  height?: number;
}

const elemtnStyling = 'tw-h-3 tw-bg-slate-200 tw-rounded';

const SkeletonList: FC<SkeletonProps> = ({ height = 200 }) => {
  return (
    <div className='tw-p-4 tw-border-DIVIDER_GRAY tw-shadow tw-rounded-md  '>
      <div style={{ height: height + 'px' }} className='tw-overflow-hidden '>
        <div className='tw-flex'>
          <div className='tw-w-[5%]'>
            <div className='tw-h-6 tw-w-6 tw-bg-slate-200 tw-rounded-full '></div>
          </div>
          <div className='tw-w-[80%]'>
            <div className='tw-animate-pulse tw-flex tw-space-x-4'>
              <div className='tw-flex-1 tw-space-y-6 tw-py-1'>
                <div className={`${elemtnStyling} tw-w-[30%]`}></div>
                <div className={`${elemtnStyling} tw-w-[60%]`}></div>
              </div>
            </div>
          </div>
        </div>
        <div className='tw-flex tw-mt-8'>
          <div className='tw-w-[5%]'>
            <div className='tw-h-6 tw-w-6 tw-bg-slate-200 tw-rounded-full '></div>
          </div>
          <div className='tw-w-[80%]'>
            <div className='tw-animate-pulse tw-flex tw-space-x-4'>
              <div className='tw-flex-1 tw-space-y-6 tw-py-1'>
                <div className={`${elemtnStyling} tw-w-[30%]`}></div>
                <div className={`${elemtnStyling} tw-w-[80%]`}></div>
              </div>
            </div>
          </div>
        </div>
        <div className='tw-flex tw-mt-8'>
          <div className='tw-w-[5%]'>
            <div className='tw-h-6 tw-w-6 tw-bg-slate-200 tw-rounded-full '></div>
          </div>
          <div className='tw-w-[80%]'>
            <div className='tw-animate-pulse tw-flex tw-space-x-4'>
              <div className='tw-flex-1 tw-space-y-6 tw-py-1'>
                <div className={`${elemtnStyling} tw-w-[30%]`}></div>
                <div className={`${elemtnStyling} tw-w-[60%]`}></div>
              </div>
            </div>
          </div>
        </div>
        <div className='tw-flex tw-mt-8'>
          <div className='tw-w-[5%]'>
            <div className='tw-h-6 tw-w-6 tw-bg-slate-200 tw-rounded-full '></div>
          </div>
          <div className='tw-w-[80%]'>
            <div className='tw-animate-pulse tw-flex tw-space-x-4'>
              <div className='tw-flex-1 tw-space-y-6 tw-py-1'>
                <div className={`${elemtnStyling} tw-w-[30%]`}></div>
                <div className={`${elemtnStyling} tw-w-[80%]`}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonList;
