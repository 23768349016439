import { Dispatch, FC, SetStateAction, useState } from 'react';
import { KybTncConfigType } from 'modules/kyb/kyb.types';
import KybTncDetail from 'modules/kyb/KybTncDetail';
import { defaultFnType } from 'types';
import { LinkType } from 'types/tncApi.types';

interface TncPendingDetailsType {
  details: KybTncConfigType[];
  links: Record<string, LinkType>;
  onLinkClick: defaultFnType;
  setIsCheckboxesChecked: Dispatch<SetStateAction<boolean>>;
}

const TncPendingDetails: FC<TncPendingDetailsType> = ({ details, links, onLinkClick, setIsCheckboxesChecked }) => {
  const [checkboxValues, setCheckboxValues] = useState<boolean[]>(Array(details.length)?.fill(false));

  const checkAndSetIfAllCheckBoxesChecked = (checkboxValues: boolean[]) => {
    const numberOfChecked = checkboxValues.filter(Boolean).length;
    const numberOfCheckboxes = details.filter((item) => item.checkbox_label).length;

    setIsCheckboxesChecked(numberOfChecked === numberOfCheckboxes);
  };

  const handleCheckBox = (index: number) => {
    setCheckboxValues((prev) => {
      const arr = [...prev];

      arr[index] = !arr[index];

      checkAndSetIfAllCheckBoxesChecked(arr);

      return arr;
    });
  };

  return (
    <>
      {details?.map((item, index) => (
        <KybTncDetail
          index={index}
          item={item}
          checkboxValues={checkboxValues}
          onCheckBoxClick={handleCheckBox}
          links={links}
          key={`pending-tnc-detail-${index}`}
          onLinkClick={onLinkClick}
        />
      ))}
    </>
  );
};

export default TncPendingDetails;
