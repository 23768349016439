const mixpanelEvents = {
  // Login || Signup Events
  INITIALIZE: 'APP_INITIALIZE',
  IDENTIFY: 'IDENTIFY',
  SIGNUP_CLICK: 'SIGNUP_CLICK',
  SIGNUP_PAGE_LOAD: 'SIGNUP_PAGE_LOAD',
  SIGNUP_SUBMIT: 'SIGNUP_SUBMIT',
  SIGNUP_OTP_REQUEST: 'SIGNUP_OTP_REQUEST',
  SIGNUP_OTP_RESEND: 'SIGNUP_OTP_RESEND',
  LOGIN_CLICK: 'LOGIN_CLICK',
  LOGIN_PAGE_LOAD: 'LOGIN_PAGE_LOAD',
  LOGIN_SUBMIT: 'LOGIN_SUBMIT',
  LOGIN_OTP_REQUEST: 'LOGIN_OTP_REQUEST',
  LOGIN_OTP_RESEND: 'LOGIN_OTP_RESEND',
  LOGOUT_BUTTON_CLICK: 'LOGOUT_BUTTON_CLICK',
  LEGAL_PRIVACY_LINK_CLICK: 'LEGAL_PRIVACY_LINK_CLICK',
  ALIAS: 'ALIAS',
  SET_USER_PROPERTIES: 'SET_USER_PROPERTIES',
  RESET: 'RESET',
  EDIT_EMAIL_CTA: 'EDIT_EMAIL_CTA',

  // Sidebar events
  ROMA_HOME_SIDEBAR_CLICK: 'ROMA_HOME_SIDEBAR_CLICK',
  YIELD_HOME_SIDEBAR_CLICK: 'YIELD_HOME_SIDEBAR_CLICK',
  TRANSACTION_SIDEBAR_CLICK: 'TRANSACTION_SIDEBAR_CLICK',
  BENEFICIARY_SIDEBAR_CLICK: 'BENEFICIARY_SIDEBAR_CLICK',
  YIELD_TRANSACTION_SIDEBAR_CLICK: 'YIELD_TRANSACTION_SIDEBAR_CLICK',
  YIELD_INVESTMENT_PLANNER_SIDEBAR_CLICK: 'YIELD_INVESTMENT_PLANNER_SIDEBAR_CLICK',
  YIELD_MY_INVESTMENTS_SIDEBAR_CLICK: 'YIELD_MY_INVESTMENTS_SIDEBAR_CLICK',
  YIELD_ACCOUNTS_SIDEBAR_CLICK: 'YIELD_ACCOUNTS_SIDEBAR_CLICK',

  //Home Page Events
  ROMA_HOME_PAGE_LOAD: 'ROMA_HOME_PAGE_LOAD',
  YIELD_HOME_PAGE_LOAD: 'YIELD_HOME_PAGE_LOAD',
  WALLET_CLICK: 'WALLET_CLICK',
  COPY_WALLET_DETAILS_CLICK: 'COPY_WALLET_DETAILS_CLICK',
  CLICK_DEPOSIT_ACTION_BUTTON: 'CLICK_DEPOSIT_ACTION_BUTTON',
  WALLET_DETAILS_SHOW_ALL_CLICK: 'WALLET_DETAILS_SHOW_ALL_CLICK',
  VIEW_ALL_TRANSACTIONS_CLICK: 'VIEW_ALL_TRANSACTIONS_CLICK',
  TRANSACTION_DETAILS_CLICK: 'TRANSACTION_DETAILS_CLICK',
  HELP_CLICK: 'HELP_CLICK',
  PAGE_ISSUE_TRACKER: 'PAGE_ISSUE_TRACKER',

  // Transactions Events
  TRANSACTION_PAGE_LOAD: 'TRANSACTION_PAGE_LOAD',
  YIELD_TRANSACTIONS_LOAD: 'YIELD_TRANSACTIONS_LOAD',

  // Beneficiary Events
  BENEFICIARY_PAGE_LOAD: 'BENEFICIARY_PAGE_LOAD',
  BENEFICIARY_DETAILS_CLICK: 'BENEFICIARY_DETAILS_CLICK',
  BENEFICIARY_SEND_MONEY_CLICK: 'BENEFICIARY_SEND_MONEY_CLICK',
  BENEFICIARY_EDIT_CLICK: 'BENEFICIARY_EDIT_CLICK',
  BENEFICIARY_DELETE_CLICK: 'BENEFICIARY_DELETE_CLICK',
  BENEFICIARY_EDIT_CONFIRM_BUTTON_CLICK: 'BENEFICIARY_EDIT_CONFIRM_BUTTON_CLICK',
  BENEFICIARY_EDIT_CONFIRM_SUCCESSFUL: 'BENEFICIARY_EDIT_CONFIRM_SUCCESSFUL',
  BENEFICIARY_EDIT_CONFIRM_FAILED: 'BENEFICIARY_EDIT_CONFIRM_FAILED',
  BENEFICIARY_EDIT_CANCEL_BUTTON_CLICK: 'BENEFICIARY_EDIT_CANCEL_BUTTON_CLICK',
  BENEFICIARY_DELETE_CONFIRM_BUTTON_CLICK: 'BENEFICIARY_DELETE_CONFIRM_BUTTON_CLICK',
  BENEFICIARY_DELETE_CONFIRM_SUCCESSFUL: 'BENEFICIARY_DELETE_CONFIRM_SUCCESSFUL',
  BENEFICIARY_DELETE_CONFIRM_FAILED: 'BENEFICIARY_DELETE_CONFIRM_FAILED',
  BENEFICIARY_DELETE_CANCEL_BUTTON_CLICK: 'BENEFICIARY_DELETE_CANCEL_BUTTON_CLICK',
  BENEFICIARY_SEARCH: 'BENEFICIARY_SEARCH',
  BENEFICIARY_FILTER: 'BENEFICIARY_FILTER',
  COUNTER_PARTY_EDIT_CLICK: 'COUNTER_PARTY_EDIT_CLICK',
  COUNTER_PARTY_DELETE_CLICK: 'COUNTER_PARTY_DELETE_CLICK',
  COUNTER_PARTY_SEND_MONEY_CLICK: 'COUNTER_PARTY_SEND_MONEY_CLICK',

  //Transaction Events
  TRANSACTION_FILTER_CLICK: 'TRANSACTION_FILTER_CLICK',

  //KYB Events
  KYB_BANNER_ACTIVATE_BUTTON_CLICK: 'KYB_BANNER_ACTIVATE_BUTTON_CLICK',
  KYB_PAGE_NEXT_CLICKED: 'KYB_PAGE_NEXT_CLICKED',
  KYB_TNC_ACCEPT_CLICK: 'KYB_TNC_ACCEPT_CLICK',
  KYB_SUBMIT_CLICK: 'KYB_SUBMIT_CLICK',
  KYB_SUBMIT_SUCCESSFUL: 'KYB_SUBMIT_SUCCESSFUL',
  KYB_SUBMIT_FAILED: 'KYB_SUBMIT_FAILED',
  KYB_INITIATED_PAGE_LOAD: 'KYB_INITIATED_PAGE_LOAD',
  KYB_ESC_BUTTON_CLICK: 'KYB_ESC_BUTTON_CLICK',
  KYB_ESC_CONFIRM_BUTTON_CLICK: 'KYB_ESC_CONFIRM_BUTTON_CLICK',
  KYB_ESC_CANCEL_BUTTON_CLICK: 'KYB_ESC_CANCEL_BUTTON_CLICK',
  KYB_INITIATED_PAGE_CLOSE_BUTTON_CLICK: 'KYB_INITIATED_PAGE_CLOSE_BUTTON_CLICK',
  KYB_CLIENT_IP_ADDRESS_RESPONSE_TIME: 'KYB_CLIENT_IP_ADDRESS_RESPONSE_TIME',
  KYB_PAGE_NEXT_ERROR: 'KYB_PAGE_NEXT_ERROR',
  KYB_PAGE_BACK_CLICK: 'KYB_PAGE_BACK_CLICK',
  KYB_STATUS_PAGE_LOAD: 'KYB_STATUS_PAGE_LOAD',

  //Move Money Popup Events
  TRANSFER_BUTTON_CLICK: 'TRANSFER_BUTTON_CLICK',
  SEARCH_BENEFICIARY: 'SEARCH_BENEFICIARY',
  TRANSFER_ADD_NEW_BENEFICIARY_BUTTON_CLICK: 'TRANSFER_ADD_NEW_BENEFICIARY_BUTTON_CLICK',
  TRANSFER_ADD_NEW_BENEFICIARY_CREATE_BUTTON_CLICK: 'TRANSFER_ADD_NEW_BENEFICIARY_CREATE_BUTTON_CLICK',
  TRANSFER_ADD_NEW_BENEFICIARY_SUCCESSFUL: 'TRANSFER_ADD_NEW_BENEFICIARY_SUCCESSFUL',
  TRANSFER_ADD_NEW_BENEFICIARY_FAILED: 'TRANSFER_ADD_NEW_BENEFICIARY_FAILED',
  TRANSFER_ADD_NEW_BENEFICIARY_ESC: 'TRANSFER_ADD_NEW_BENEFICIARY_ESC',
  TRANSFER_SELECT_BENEFICIARY_CLICK: 'TRANSFER_SELECT_BENEFICIARY_CLICK',
  TRANSFER_CHANGE_BENEFICIARY_BUTTON_CLICK: 'TRANSFER_CHANGE_BENEFICIARY_BUTTON_CLICK',
  TRANSFER_CHANGE_CURRENCY_DROPDOWN_CLICK: 'TRANSFER_CHANGE_CURRENCY_DROPDOWN_CLICK',
  TRANSFER_SELECT_CURRENCY_CLICK: 'TRANSFER_SELECT_CURRENCY_CLICK',
  TRANSFER_GET_QUOTE_NEXT_BUTTON_CLICK: 'TRANSFER_GET_QUOTE_NEXT_BUTTON_CLICK',
  TRANSFER_QUOTE_LOADED_SUCCESFULLY: 'TRANSFER_QUOTE_LOADED_SUCCESFULLY',
  TRANSFER_QUOTE_LOADING_FAILED: 'TRANSFER_QUOTE_LOADING_FAILED',
  TRANSFER_SWIPE_TO_CONFIRM_SWIPED: 'TRANSFER_SWIPE_TO_CONFIRM_SWIPED',
  TRANSFER_INITIATION_SUCCESSFUL: 'TRANSFER_INITIATION_SUCCESSFUL',
  TRANSFER_INITIIATION_FAILED: 'TRANSFER_INITIIATION_FAILED',
  TRANSFER_INITIATION_CONFIRMATION_PAGE_LOAD: 'TRANSFER_INITIATION_CONFIRMATION_PAGE_LOAD',
  TRANSFER_SUCCESSFUL_VIEW_PAYMENTS: 'TRANSFER_SUCCESSFUL_VIEW_PAYMENTS',
  TRANSFER_ESC_BUTTON: 'TRANSFER_ESC_BUTTON',

  //User Session Expiry Event
  SESSION_EXPIRY_ALERT_CLICK: 'SESSION_EXPIRY_ALERT_CLICK',

  //Payment Requests
  REQUEST_PAYMENT_SUBMIT_CLICKED: 'REQUEST_PAYMENT_SUBMIT',
  REQUEST_PAYMENT_DISCARD_CLICKED: 'REQUEST_PAYMENT_DISCARD',
  REQUEST_PAYMENT_ESC_BUTTON: 'REQUEST_PAYMENT_ESC_BUTTON',
  REQUEST_PAYMENT_VIEW_ALL_REQUESTS: 'REQUEST_PAYMENT_VIEW_ALL_REQUESTS',
  REQUEST_PAYMENT_CLICKED: 'REQUEST_PAYMENT_CLICKED',
  REQUEST_PAYMENT_SIDEBAR_CLICK: 'REQUEST_PAYMENT_SIDEBAR_CLICK',
  REQUEST_PAYMENT_RETRY_CLICK: 'REQUEST_PAYMENT_RETRY_CLICK',

  //Investment Planner
  YIELD_INVESTMENT_PLANNER_LOAD: 'YIELD_INVESTMENT_PLANNER_LOAD',
  INVESTMENT_PLANNER_ALL_ASSETS_CLICK: 'INVESTMENT_PLANNER_ALL_ASSETS_CLICK',
  INVESTMENT_PLANNER_ALL_ASSETS_MATURITY_FILTER_CLICK: 'INVESTMENT_PLANNER_ALL_ASSETS_MATURITY_FILTER_CLICK',
  INVESTMENT_PLANNER_ALL_ASSETS_MATURITY_FILTER_APPLY_CLICK:
    'INVESTMENT_PLANNER_ALL_ASSETS_MATURITY_FILTER_APPLY_CLICK',
  INVESTMENT_PLANNER_ALL_ASSETS_MATURITY_FILTER_RESET_CLICK:
    'INVESTMENT_PLANNER_ALL_ASSETS_MATURITY_FILTER_RESET_CLICK',
  INVESTMENT_PLANNER_ALL_ASSETS_YIELD_FILTER_CLICK: 'INVESTMENT_PLANNER_ALL_ASSETS_YIELD_FILTER_CLICK',
  INVESTMENT_PLANNER_ALL_ASSETS_YIELD_FILTER_APPLY_CLICK: 'INVESTMENT_PLANNER_ALL_ASSETS_YIELD_FILTER_APPLY_CLICK',
  INVESTMENT_PLANNER_ALL_ASSETS_YIELD_FILTER_RESET_CLICK: 'INVESTMENT_PLANNER_ALL_ASSETS_YIELD_FILTER_RESET_CLICK',
  INVESTMENT_PLANNER_ALL_ASSETS_CHECKBOX_CLICK: 'INVESTMENT_PLANNER_ALL_ASSETS_CHECKBOX_CLICK',
  INVESTMENT_PLANNER_ALL_ASSETS_CHECKBOX_REMOVE: 'INVESTMENT_PLANNER_ALL_ASSETS_CHECKBOX_REMOVE',
  INVESTMENT_PLANNER_ALL_ASSETS_CHECKBOX_SUCCESSFUL: 'INVESTMENT_PLANNER_ALL_ASSETS_CHECKBOX_SUCCESSFUL',
  INVESTMENT_PLANNER_ALL_ASSETS_CHECKBOX_FAILED: 'INVESTMENT_PLANNER_ALL_ASSETS_CHECKBOX_FAILED',
  INVESTMENT_PLANNER_ALL_ASSETS_CHECKBOX_ADDED_TO_PORTFOLIO_BUILDER:
    'INVESTMENT_PLANNER_ALL_ASSETS_CHECKBOX_ADDED_TO_PORTFOLIO_BUILDER',
  INVESTMENT_PLANNER_ALL_ASSETS_API_SUCCESSFUL: 'INVESTMENT_PLANNER_ALL_ASSETS_API_SUCCESSFUL',
  INVESTMENT_PLANNER_ALL_ASSETS_API_FAILED: 'INVESTMENT_PLANNER_ALL_ASSETS_API_FAILED',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_CLICK: 'INVESTMENT_PLANNER_SAVED_PORTFOLIO_CLICK',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_SUCCESSFUL: 'INVESTMENT_PLANNER_SAVED_PORTFOLIO_SUCCESSFUL',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_FAILED: 'INVESTMENT_PLANNER_SAVED_PORTFOLIO_FAILED',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_NO_ASSETS: 'INVESTMENT_PLANNER_SAVED_PORTFOLIO_NO_ASSETS',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_DELETE_CLICK: 'INVESTMENT_PLANNER_SAVED_PORTFOLIO_DELETE_CLICK',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_CONFIRM_DELETE_CLICK: 'INVESTMENT_PLANNER_SAVED_PORTFOLIO_CONFIRM_DELETE_CLICK',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_CONFIRM_DELETE_CLICK_SUCCESS:
    'INVESTMENT_PLANNER_SAVED_PORTFOLIO_CONFIRM_DELETE_CLICK_SUCCESS',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_CONFIRM_DELETE_CLICK_FAIL:
    'INVESTMENT_PLANNER_SAVED_PORTFOLIO_CONFIRM_DELETE_CLICK_FAIL',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_CONFIRM_CANCEL_CLICK: 'INVESTMENT_PLANNER_SAVED_PORTFOLIO_CONFIRM_CANCEL_CLICK',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_CONFIRM_CROSS_CLICK: 'INVESTMENT_PLANNER_SAVED_PORTFOLIO_CONFIRM_CROSS_CLICK',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_CUSTOMIZE_CLICK: 'INVESTMENT_PLANNER_SAVED_PORTFOLIO_CUSTOMIZE_CLICK',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_CUSTOMIZE_SUCCESSFUL: 'INVESTMENT_PLANNER_SAVED_PORTFOLIO_CUSTOMIZE_SUCCESSFUL',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_CUSTOMIZE_FAILED: 'INVESTMENT_PLANNER_SAVED_PORTFOLIO_CUSTOMIZE_FAILED',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_FAILED: 'INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_FAILED',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_SUCCESSFUL:
    'INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_SUCCESSFUL',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_CLICK: 'INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_CLICK',
  INVESTMENT_PLANNER_CUSTOMIZE_ESC_CLICK: 'INVESTMENT_PLANNER_CUSTOMIZE_ESC_CLICK',
  INVESTMENT_PLANNER_CUSTOMIZE_EDIT_NAME_CLICK: 'INVESTMENT_PLANNER_CUSTOMIZE_EDIT_NAME_CLICK',
  INVESTMENT_PLANNER_CUSTOMIZE_SAVE_NAME_CLICK: 'INVESTMENT_PLANNER_CUSTOMIZE_SAVE_NAME_CLICK',
  INVESTMENT_PLANNER_CUSTOMIZE_DELETE_ASSET_CLICK: 'INVESTMENT_PLANNER_CUSTOMIZE_DELETE_ASSET_CLICK',
  INVESTMENT_PLANNER_CUSTOMIZE_DELETE_ASSET_CONFIRM_DELETE_CLICK:
    'INVESTMENT_PLANNER_CUSTOMIZE_DELETE_ASSET_CONFIRM_DELETE_CLICK',
  INVESTMENT_PLANNER_CUSTOMIZE_DELETE_ASSET_CONFIRM_CANCEL_CLICK:
    'INVESTMENT_PLANNER_CUSTOMIZE_DELETE_ASSET_CONFIRM_CANCEL_CLICK',
  INVESTMENT_PLANNER_CUSTOMIZE_DELETE_ASSET_CONFIRM_CROSS_CLICK:
    'INVESTMENT_PLANNER_CUSTOMIZE_DELETE_ASSET_CONFIRM_CROSS_CLICK',
  INVESTMENT_PLANNER_CUSTOMIZE_MODIFY_SUCCESSFUL: 'INVESTMENT_PLANNER_CUSTOMIZE_MODIFY_SUCCESSFUL',
  INVESTMENT_PLANNER_CUSTOMIZE_MODIFY_FAILED: 'INVESTMENT_PLANNER_CUSTOMIZE_MODIFY_FAILED',
  INVESTMENT_PLANNER_CUSTOMIZE_ADD_NEW_ASSET_CLICK: 'INVESTMENT_PLANNER_CUSTOMIZE_ADD_NEW_ASSET_CLICK',
  INVESTMENT_PLANNER_CUSTOMIZE_SORT_CLICK: 'INVESTMENT_PLANNER_CUSTOMIZE_SORT_CLICK',
  INVESTMENT_PLANNER_CUSTOMIZE_SORT_SELECTED: 'INVESTMENT_PLANNER_CUSTOMIZE_SORT_SELECTED',
  INVESTMENT_PLANNER_CUSTOMIZE_MATURITY_FILTER_CLICK: 'INVESTMENT_PLANNER_CUSTOMIZE_MATURITY_FILTER_CLICK',
  INVESTMENT_PLANNER_CUSTOMIZE_MATURITY_FILTER_APPLY_CLICK: 'INVESTMENT_PLANNER_CUSTOMIZE_MATURITY_FILTER_APPLY_CLICK',
  INVESTMENT_PLANNER_CUSTOMIZE_MATURITY_FILTER_RESET_CLICK: 'INVESTMENT_PLANNER_CUSTOMIZE_MATURITY_FILTER_RESET_CLICK',
  INVESTMENT_PLANNER_CUSTOMIZE_YIELD_FILTER_CLICK: 'INVESTMENT_PLANNER_CUSTOMIZE_YIELD_FILTER_CLICK',
  INVESTMENT_PLANNER_CUSTOMIZE_YIELD_FILTER_APPLY_CLICK: 'INVESTMENT_PLANNER_CUSTOMIZE_YIELD_FILTER_APPLY_CLICK',
  INVESTMENT_PLANNER_CUSTOMIZE_YIELD_FILTER_RESET_CLICK: 'INVESTMENT_PLANNER_CUSTOMIZE_YIELD_FILTER_RESET_CLICK',
  INVESTMENT_PLANNER_CUSTOMIZE_CHECKBOX_CLICK: 'INVESTMENT_PLANNER_CUSTOMIZE_CHECKBOX_CLICK',
  INVESTMENT_PLANNER_CUSTOMIZE_CHECKBOX_REMOVE: 'INVESTMENT_PLANNER_CUSTOMIZE_CHECKBOX_REMOVE',
  INVESTMENT_PLANNER_CUSTOMIZE_SAVE_AS_NEW_CLICK: 'INVESTMENT_PLANNER_CUSTOMIZE_SAVE_AS_NEW_CLICK',
  INVESTMENT_PLANNER_CUSTOMIZE_SAVE_AS_NEW_SUCCESSFUL: 'INVESTMENT_PLANNER_CUSTOMIZE_SAVE_AS_NEW_SUCCESSFUL',
  INVESTMENT_PLANNER_CUSTOMIZE_SAVE_AS_NEW_FAILED: 'INVESTMENT_PLANNER_CUSTOMIZE_SAVE_AS_NEW_FAILED',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_INVEST_NOW_CLICK:
    'INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_INVEST_NOW_CLICK',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_INVEST_NOW_SUCCESSFUL:
    'INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_INVEST_NOW_SUCCESSFUL',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_INVEST_NOW_FAILED:
    'INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_INVEST_NOW_FAILED',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_INVEST_NOW_DISCLAIMER_PLACE_ORDER_CLICK:
    'INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_INVEST_NOW_DISCLAIMER_PLACE_ORDER_CLICK',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_INVEST_NOW_DISCLAIMER_CROSS_CLICK:
    'INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_INVEST_NOW_DISCLAIMER_CROSS_CLICK',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_INVEST_PAGE_NEXT_CLICK:
    'INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_INVEST_PAGE_NEXT_CLICK',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_INVEST_PAGE_BACK_CLICK:
    'INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_INVEST_PAGE_BACK_CLICK',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_INVEST_PAGE_ESC_CLICK:
    'INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_INVEST_PAGE_ESC_CLICK',
  INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_INVEST_PAGE_ADD_FUNDS_CLICK:
    'INVESTMENT_PLANNER_SAVED_PORTFOLIO_VIEW_DETAILS_INVEST_PAGE_ADD_FUNDS_CLICK',
  CREATE_RECOMMENDED_PORTFOLIO_CLICK: 'CREATE_RECOMMENDED_PORTFOLIO_CLICK',
  INVESTMENT_PLANNER_RECOMMENDATION_ENGINE_CLICK: 'INVESTMENT_PLANNER_RECOMMENDATION_ENGINE_CLICK',
  INVESTMENT_PLANNER_RECOMMENDATION_ENGINE_QUESTIONNAIRE_CLICK:
    'INVESTMENT_PLANNER_RECOMMENDATION_ENGINE_QUESTIONNAIRE_CLICK',
  INVESTMENT_PLANNER_SINGLE_ASSET_INVEST_NOW_CLICK: 'INVESTMENT_PLANNER_SINGLE_ASSET_INVEST_NOW_CLICK',
  INVESTMENT_PLANNER_SINGLE_ASSET_INVEST_NOW_DISCLAIMER_CLICK:
    'INVESTMENT_PLANNER_SINGLE_ASSET_INVEST_NOW_DISCLAIMER_CLICK',

  //Recommendation Engine
  RECOMMEND_PORTFOLIO_NEXT_CLICKED: 'RECOMMEND_PORTFOLIO_NEXT_CLICKED',
  RECOMMEND_PORTFOLIO_SUBMIT_CLICK: 'RECOMMEND_PORTFOLIO_SUBMIT_CLICK',
  RECOMMEND_PORTFOLIO_SUBMIT_FAILED: 'RECOMMEND_PORTFOLIO_SUBMIT_FAILED',
  RECOMMEND_PORTFOLIO_SUBMIT_SUCCESSFUL: 'RECOMMEND_PORTFOLIO_SUBMIT_SUCCESSFUL',
  RECOMMEND_PORTFOLIO_BACK_CLICK: 'RECOMMEND_PORTFOLIO_BACK_CLICK',
  RECOMMEND_PORTFOLIO_CANCEL_BUTTON_CLICK: 'RECOMMEND_PORTFOLIO_CANCEL_BUTTON_CLICK',

  //My Investments
  YIELD_MY_INVESTMENTS_LOAD: 'YIELD_MY_INVESTMENTS_LOAD',

  //Accounts
  ACCOUNTS_PAGE_OPENED_SUCCESSFUL: 'ACCOUNTS_PAGE_OPENED_SUCCESSFUL',
  ACCOUNTS_PAGE_SHOW_WIRE_DETAILS_CLICK: 'ACCOUNTS_PAGE_SHOW_WIRE_DETAILS_CLICK',
  ACCOUNTS_PAGE_WITHDRAW_CLICK: 'ACCOUNTS_PAGE_WITHDRAW_CLICK',
  ACCOUNTS_PAGE_WITHDRAW_AMOUNT_ENTERED: 'ACCOUNTS_PAGE_WITHDRAW_AMOUNT_ENTERED',
  ACCOUNTS_PAGE_WITHDRAW_NEXT_CLICK: 'ACCOUNTS_PAGE_WITHDRAW_NEXT_CLICK',
  ACCOUNTS_PAGE_WITHDRAW_BACK_CLICK: 'ACCOUNTS_PAGE_WITHDRAW_BACK_CLICK',
  ACCOUNTS_PAGE_WITHDRAW_CONFIRM_2FA_ENTERED: 'ACCOUNTS_PAGE_WITHDRAW_CONFIRM_2FA_ENTERED',
  ACCOUNTS_PAGE_WITHDRAW_CONFIRM_2FA_ENABLE_NOW: 'ACCOUNTS_PAGE_WITHDRAW_CONFIRM_2FA_ENABLE_NOW',
  MY_INVESTMENTS_MATURED_INVESTMENTS_FAILED: 'MY_INVESTMENTS_MATURED_INVESTMENTS_FAILED',
  MY_INVESTMENTS_ACTIVE_INVESTMENTS_FAILED: 'MY_INVESTMENTS_ACTIVE_INVESTMENTS_FAILED',
  MY_INVESTMENTS_MATURED_INVESTMENTS_SUCCESSFUL: 'MY_INVESTMENTS_MATURED_INVESTMENTS_SUCCESSFUL',
  MY_INVESTMENTS_ACTIVE_INVESTMENTS_SUCCESSFUL: 'MY_INVESTMENTS_ACTIVE_INVESTMENTS_SUCCESSFUL',
  MY_INVESTMENTS_ACTIVE_INVESTMENTS_CLICK: 'MY_INVESTMENTS_ACTIVE_INVESTMENTS_CLICK',
  MY_INVESTMENTS_MATURED_INVESTMENTS_CLICK: 'MY_INVESTMENTS_MATURED_INVESTMENTS_CLICK',
  MY_INVESTMENTS_PENDING_INVESTMENTS_CLICK: 'MY_INVESTMENTS_PENDING_INVESTMENTS_CLICK',
  ACCOUNT_PAGE_ACCOUNT_DETAILS_SUCCESSFUL: 'ACCOUNT_PAGE_ACCOUNT_DETAILS_SUCCESSFUL',
  ACCOUNT_PAGE_ACCOUNT_DETAILS_FAILED: 'ACCOUNT_PAGE_ACCOUNT_DETAILS_FAILED',
  ACCOUNT_PAGE_ACCOUNT_SUMMERY_SUCCESSFUL: 'ACCOUNT_PAGE_ACCOUNT_SUMMERY_SUCCESSFUL',
  ACCOUNT_PAGE_ACCOUNT_SUMMERY_FAILED: 'ACCOUNT_PAGE_ACCOUNT_SUMMERY_FAILED',
  ACCOUNT_DETAILS_SEND_MONEY_CLICK: 'ACCOUNT_DETAILS_SEND_MONEY_CLICK',

  // Common Events
  PAGE_NAVIGATION: 'PAGE_NAVIGATION',
  NETWORK_TOGGLE: 'NETWORK_TOGGLE',
  API_TIME_LOG: 'API_TIME_LOG',
  OTP_CHANGE: 'OTP_CHANGE',

  CANCEL_BULK_PAYMENT_YES: 'CANCEL_BULK_PAYMENT_YES',
  CANCEL_BULK_PAYMENT_NO: 'CANCEL_BULK_PAYMENT_NO',

  NO_PERMISSIONS_RENDERED: 'NO_PERMISSIONS_RENDERED',
};

export default mixpanelEvents;
