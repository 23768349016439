import { FC } from 'react';
import { SKELETON_SIZES } from 'constants/skeletons';

interface SkeletonProps {
  height?: number;
  isAsset?: boolean;
}

const elemtnStyling = 'tw-h-2 tw-animate-pulse tw-bg-slate-200 tw-rounded';

const SkeletonTable: FC<SkeletonProps> = ({ height = 600, isAsset = false }) => {
  return (
    <div className='tw-p-4  tw-rounded-md  '>
      <div style={{ height: height + 'px' }} className='tw-overflow-hidden '>
        <div className='tw-flex tw-mb-4 tw-justify-between tw-border-b tw-border-DIVIDER_GRAY tw-py-4'>
          <div className={`${elemtnStyling} tw-w-[5%]`}></div>
          <div className={`${elemtnStyling} tw-w-[20%]`}></div>
          <div className={`${elemtnStyling} tw-w-[10%]`}></div>
          <div className={`${elemtnStyling} tw-w-[15%]`}></div>
          <div className={`${elemtnStyling} tw-w-[20%]`}></div>
          <div className={`${elemtnStyling} tw-w-[8%]`}></div>
        </div>
        {SKELETON_SIZES.map((key) => (
          <div className='tw-flex tw-justify-between  tw-py-6' key={key}>
            <div className={`${elemtnStyling} tw-w-[5%]`}></div>
            {isAsset ? (
              <div className='tw-w-[20%]'>
                <div className='tw-flex tw-justify-between'>
                  <div className='tw-w-[5%]'>
                    <div className='tw-h-4 tw-w-4 tw-bg-slate-200 tw-rounded-full '></div>
                  </div>
                  <div className='tw-w-[85%]'>
                    <div className='tw-animate-pulse tw-flex tw-space-x-4'>
                      <div className='tw-flex-1 tw-space-y-2 '>
                        <div className={`${elemtnStyling} tw-w-[60%]`}></div>
                        <div className={`${elemtnStyling} tw-w-[30%]`}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='tw-w-[20%]'>
                <div className={`${elemtnStyling}`} style={{ width: 50 + key + '%' }}></div>
              </div>
            )}
            <div className='tw-w-[10%]'>
              <div className={`${elemtnStyling}`} style={{ width: 100 - key + '%' }}></div>
            </div>
            <div className={`${elemtnStyling} tw-w-[15%]`}></div>
            <div className='tw-w-[20%]'>
              <div className={`${elemtnStyling}`} style={{ width: 50 + key + '%' }}></div>
            </div>
            <div className={`${elemtnStyling} !tw-h-3 tw-w-[8%]`}></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkeletonTable;
