import { createSlice } from '@reduxjs/toolkit';
import { MapAny } from 'types';
import { PermissionType } from 'types/access';

export type UserState = {
  user: any;
  userAccessFlags: any;
  userSessionExpired?: boolean;
  isGodMode?: boolean;
  configuration?: MapAny;
  navigation?: MapAny;
  merchantDetails?: MapAny;
  permissions?: PermissionType[];
  roles?: { id: string; name: string }[];
};

const initialState: UserState = {
  user: {},
  userAccessFlags: {},
  userSessionExpired: false,
  isGodMode: false,
  configuration: {},
  navigation: {},
  merchantDetails: {},
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.user = action.payload;
      state.userSessionExpired = false;
    },
    setUserAccessFlags: (state, action) => {
      state.userAccessFlags = { ...state.userAccessFlags, ...action.payload };
    },
    setUserSessionExpired: (state, action) => {
      state.userSessionExpired = state.user.id ? action.payload : state.userSessionExpired;
    },
    setConfiguration: (state, action) => {
      state.configuration = action.payload;
    },
    setNavigation: (state, action) => {
      state.navigation = action.payload;
    },
    setIsGodMode: (state, action) => {
      state.isGodMode = action.payload;
    },
    setMerchantDetails: (state, action) => {
      state.merchantDetails = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    resetUser: () => {
      return initialState;
    },
  },
});

export const {
  setUserInfo,
  setUserAccessFlags,
  setUserSessionExpired,
  resetUser,
  setIsGodMode,
  setConfiguration,
  setNavigation,
  setMerchantDetails,
  setPermissions,
  setRoles,
} = userSlice.actions;

export default userSlice.reducer;
