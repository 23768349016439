export const MONTH_NAME = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const TIMEZONES = {
  EST: 'EST',
  UTC: 'UTC',
};

export const DATE_FORMATS = {
  YYYYMMDD: 'yyyy-MM-dd',
  DOLLLLYYYY: 'do LLLL, yyyy',
  MMMDYYYY: 'MMM d, yyyy',
};

export const MONTHS_FULL = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
