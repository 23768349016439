import { FC } from 'react';

type BottomSheetProps = {
  isOpen: boolean;
  children: any;
  className?: string;
  handleVisibility?: (state: any | null) => void;
  closeOnClickOutside?: boolean;
  wrapperClassName?: string;
};

const Popup: FC<BottomSheetProps> = ({
  isOpen = false,
  children,
  className = '',
  handleVisibility,
  closeOnClickOutside = true,
  wrapperClassName = '',
}) => {
  if (!isOpen) return null;

  return (
    <div
      className={`tw-bg-BACKDROP_BLUE tw-transition-all tw-duration-300 tw-ease-in tw-fixed tw-w-screen tw-h-screen  tw-z-[100] tw-top-0 tw-left-0 ${
        isOpen ? 'tw-opacity-1' : 'tw-hidden tw-opacity-0'
      } ${wrapperClassName}`}
      role='presentation'
      onClick={() => {
        if (closeOnClickOutside) handleVisibility?.(false);
      }}
    >
      <div className='tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center'>
        <div
          className={`tw-transition-all tw-duration-300 tw-ease-in tw-px-5 tw-py-5 tw-rounded-xl tw-block ${className} ${
            isOpen ? ' tw-translate-y-0 tw-opacity-1' : 'tw-translate-y-[50px] tw-opacity-0'
          }`}
          role='presentation'
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Popup;
