import { FC } from 'react';
import { Button } from 'destiny/dist/components/molecules/button';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { defaultFn } from 'destiny/dist/constants/index';
import { BUTTON_SIZE_TYPES, BUTTON_STATE_TYPES, BUTTON_TYPES } from 'destiny/dist/constants/molecules/buttons';
import { defaultFnType } from 'types';
import { trackMixpanel } from 'utils/mixpanel';

interface PopupActionFooterProps {
  handlePrimaryAction?: defaultFnType;
  isPrimaryCtaDisabled?: boolean;
  handleSecondaryAction?: defaultFnType;
  secondaryCtaTitle?: string;
  primaryCtaTitle?: string;
  primryCtaWrapperClassName?: string;
  isPrimaryCtaLoading?: boolean;
  showSecondaryCta?: boolean;
  showPrimaryCta?: boolean;
  className?: string;
  secondaryCtaWrapperClassName?: string;
  id: string;
  onReset?: defaultFnType;
  resetTitle?: string;
}
const PopupActionFooter: FC<PopupActionFooterProps> = ({
  handlePrimaryAction = defaultFn,
  isPrimaryCtaDisabled = false,
  handleSecondaryAction = defaultFn,
  secondaryCtaTitle = 'Discard',
  primaryCtaTitle = 'Save',
  primryCtaWrapperClassName = 'tw-w-24',
  isPrimaryCtaLoading = false,
  showSecondaryCta = false,
  showPrimaryCta = false,
  className = 'tw-flex tw-gap-2 tw-justify-end tw-mt-8',
  secondaryCtaWrapperClassName = 'tw-w-fit',
  id,
  onReset,
  resetTitle = 'Reset All Selection',
}) => (
  <div className={className}>
    {onReset && (
      <div className='tw-flex tw-gap-2 f-12-400 tw-flex-1 tw-cursor-pointer' onClick={onReset}>
        <SvgSpriteLoader id='refresh-ccw-01' iconCategory={ICON_SPRITE_TYPES.ARROWS} width={14} height={14} />
        <div>{resetTitle}</div>
      </div>
    )}
    {showSecondaryCta && (
      <Button
        buttonProps={{
          btnType: BUTTON_TYPES.SECONDARY,
          size: BUTTON_SIZE_TYPES.MEDIUM,
          state: BUTTON_STATE_TYPES.DEFAULT,
          wrapperClass: secondaryCtaWrapperClassName,
          onClick: handleSecondaryAction,
          id: `${id}_SECONDARY_BUTTON`,
          eventCallback: trackMixpanel,
        }}
      >
        {secondaryCtaTitle}
      </Button>
    )}
    {showPrimaryCta && (
      <Button
        buttonProps={{
          btnType: BUTTON_TYPES.PRIMARY,
          size: BUTTON_SIZE_TYPES.MEDIUM,
          state: BUTTON_STATE_TYPES.DEFAULT,
          wrapperClass: primryCtaWrapperClassName,
          onClick: handlePrimaryAction,
          disabled: isPrimaryCtaDisabled,
          isLoading: isPrimaryCtaLoading,
          id: `${id}_PRIMARY_BUTTON`,
          eventCallback: trackMixpanel,
        }}
      >
        {primaryCtaTitle}
      </Button>
    )}
  </div>
);

export default PopupActionFooter;
