import { useEffect, useRef, useState } from 'react';
import { useLazyGetKYBStatusQuery } from 'api/kyb';
import { useAppDispatch, useAppSelector } from 'hooks/toolkit';
import { RootState } from 'store';
import { setKybDetails, setKybStatus } from 'store/slices/kyb';
import { setIsGodMode } from 'store/slices/user';
import { defaultFnType } from 'types';
import { getFromLocalStorage, LOCAL_STORAGE_KEYS } from 'utils/localstorage';

function useOnClickOutside(ref: any, handler: (event?: MouseEvent) => void) {
  useEffect(() => {
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) return;

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

/**
 *
 * Debounce: doesn't work on using directly on functional components
 * because it creates new instance on each re-render.
 * Use with useCallback
 *
 * TODO : Create hook
 */
function debounce(func: any, wait: number) {
  let timeout: any;

  return function (...args: any) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

const useIsSuperUser = () => {
  const { isGodMode } = useAppSelector((state: RootState) => state?.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const godMode = getFromLocalStorage(LOCAL_STORAGE_KEYS.XZAMP_GOD_MODE);

    dispatch(setIsGodMode(!!godMode));
  }, []);

  return isGodMode;
};

const useKybStatus = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state: RootState) => state.user);
  const [getKybStatus, { data, isLoading: isKybLoading, isError: isKybStatusError }] = useLazyGetKYBStatusQuery();

  useEffect(() => {
    if (user?.id) {
      getKybStatus();
    }
  }, [user?.id]);

  useEffect(() => {
    if (data) {
      dispatch(setKybStatus(data?.status));
      dispatch(setKybDetails(data));
    }
  }, [data]);

  return {
    isKybLoading,
    isKybStatusError,
    kybStatus: data?.status,
  };
};

const useHideScrollOnBody = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
};

const useBoundingClientRect = (ref: any) => {
  const [boundingValues, setBoundingValues] = useState(ref?.current?.getBoundingClientRect() || {});

  useEffect(() => {
    setBoundingValues(ref?.current?.getBoundingClientRect() || {});
  }, [ref]);

  return { ...boundingValues };
};

const useGetCountdown = (time: number, callback?: defaultFnType) => {
  const [seconds, setSeconds] = useState(time);
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);

  const getFormatedCount = (count: number) => {
    if (count > 9) return count;

    return '0' + count;
  };

  const resetCountdown = () => {
    setSeconds(time);
    startCountdown();
  };

  const stopTimer = () => {
    if (interval.current) clearInterval(interval.current);
  };

  const startCountdown = () => {
    stopTimer();
    interval.current = setInterval(() => {
      setSeconds((prev) => {
        if (prev) {
          return prev - 1;
        } else {
          stopTimer();
          if (callback) callback();

          return 0;
        }
      });
    }, 1000);
  };

  useEffect(() => {
    if (time) resetCountdown();
    () => {
      stopTimer();
    };
  }, [time]);

  return {
    totalSeconds: getFormatedCount(seconds),
    hours: getFormatedCount(Math.floor(seconds / 3600)),
    minutes: getFormatedCount(Math.floor((seconds % 3600) / 60)),
    seconds: getFormatedCount(seconds % 60),
    startCountdown,
    resetCountdown,
  };
};

export {
  debounce,
  useBoundingClientRect,
  useGetCountdown,
  useHideScrollOnBody,
  useIsSuperUser,
  useKybStatus,
  useOnClickOutside,
};
