import { SCREEN_SUPPORT, ZAMP_LOGO } from 'constants/icons';
import Image from 'next/image';

const ScreenSupport = () => {
  return (
    <div className=' tw-flex tw-p-6 tw-fixed tw-w-screen tw-h-screen  tw-bg-white tw-z-[50]  tw-items-center tw-justify-center'>
      <Image
        width={115}
        height={28}
        alt={'zamp logo'}
        className='tw-absolute tw-top-8 tw-left-8'
        src={ZAMP_LOGO}
        draggable='false'
        priority={true}
      />
      <div className='tw-flex tw-flex-col tw-items-center tw-text-center '>
        <Image
          width={260}
          height={112}
          alt={'small screen banner'}
          className=''
          src={SCREEN_SUPPORT}
          draggable='false'
          priority={true}
        />
        <div className='tw-mt-8 tw-text-TEXT_SECONDARY'>We are live on desktop only. See you there!</div>
      </div>
    </div>
  );
};

export default ScreenSupport;
