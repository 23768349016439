import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import baseApi from 'services/api';
import bulkPayoutsSlice from 'store/slices/bulk-payouts';
import configsSliceReducer from 'store/slices/config';
import kybSliceReducer from 'store/slices/kyb';
import layoutConfigReducer from 'store/slices/layout-configs';
import mfaSliceReducer from 'store/slices/mfa';
import shortcutsSliceReducer from 'store/slices/shortcuts';
import transactionsSliceReducer from 'store/slices/transactions';
import userSliceReducer from 'store/slices/user';

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    kyb: kybSliceReducer,
    transactions: transactionsSliceReducer,
    user: userSliceReducer,
    mfa: mfaSliceReducer,
    config: configsSliceReducer,
    bulkPayout: bulkPayoutsSlice,
    shortcuts: shortcutsSliceReducer,
    layoutConfig: layoutConfigReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
