import { useState } from 'react';
import { CheckBox } from 'destiny/dist/components/atoms/checkbox';
import { Tooltip } from 'destiny/dist/components/molecules/tooltip/index';
import { TooltipPositions } from 'destiny/dist/constants/molecules/tooltip';
import { MapAny, OfferedProductsType, PRODUCT_TYPES } from 'types';
import { CompanyDetailsEntityType } from 'types/merchantApi.types';
import { trackMixpanel } from 'utils/mixpanel';
import { COUNTRY_OPTIONS } from 'components/country-picker/countries';
import CountryLabel from 'components/CountryLabel';

interface EntityIntentRowProps {
  details: CompanyDetailsEntityType;
  className?: string;
  onIntentClick: (country: MapAny, product: string) => void;
  value: string[];
  offeredProducts: OfferedProductsType;
  merchantName?: string;
}

const EntityIntentRow: React.FC<EntityIntentRowProps> = ({
  details,
  className,
  onIntentClick,
  value,
  offeredProducts,
  merchantName,
}) => {
  const [isDigitalAsset, setIsDigitalAsset] = useState(true);
  const countryDetails = COUNTRY_OPTIONS?.find((each) => each.code === details?.geography);

  const noProductActive = !details?.offered_product_ids?.length;

  return (
    <div
      className={`tw-flex tw-min-h-[52px] tw-bg-BASE_SECONDARY tw-items-center tw-rounded-2.5 ${className}`}
      key={details?.geography}
    >
      <CountryLabel details={details} merchantName={merchantName} className='tw-px-4 tw-w-5/12' />

      {noProductActive ? (
        <div className='f-12-300 tw-text-TEXT_SECONDARY'>We are yet to make our service available at this location</div>
      ) : (
        <>
          {Object.keys(PRODUCT_TYPES)?.map((eachProduct) => {
            const typedProduct = eachProduct as PRODUCT_TYPES;

            return (
              <div className='tw-w-2/12 tw-flex tw-justify-center' key={eachProduct}>
                <Tooltip
                  disabled={details?.offered_product_ids?.includes(typedProduct)}
                  position={TooltipPositions.TOP}
                  tooltipBody={
                    <div className='tw-w-80 f-14-300'>
                      {offeredProducts?.[typedProduct]?.display_name} is not available in {countryDetails?.name} at this
                      time. We are working hard to make it available and hope to onboard you as soon as possible
                    </div>
                  }
                >
                  <CheckBox
                    disabled={!details?.offered_product_ids?.includes(eachProduct as PRODUCT_TYPES) ?? false}
                    checked={value?.includes(eachProduct)}
                    id={`INTENT_${details.geography}_${eachProduct}_CHECKBOX`.toUpperCase()}
                    eventCallback={trackMixpanel}
                    onPress={() => onIntentClick?.(details, eachProduct)}
                  />
                </Tooltip>
              </div>
            );
          })}
          <div className='tw-w-2/12 tw-flex tw-justify-center'>
            <CheckBox
              checked={isDigitalAsset}
              id={`DIGITAL_ASSET_CHECKBOX`}
              eventCallback={trackMixpanel}
              onPress={() => setIsDigitalAsset((prev) => !prev)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EntityIntentRow;
