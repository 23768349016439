import { FC, useState } from 'react';
import { useAcceptPendingTermsAndConditionsMutation, useGetPendingTermsAndConditionsQuery } from 'api/tnc';
import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { Button } from 'destiny/dist/components/molecules/button';
import { Tooltip } from 'destiny/dist/components/molecules/tooltip/index';
import { COLORS } from 'destiny/dist/constants/colors';
import { BUTTON_SIZE_TYPES } from 'destiny/dist/constants/molecules/buttons';
import { TooltipPositions } from 'destiny/dist/constants/molecules/tooltip';
import { useAppSelector } from 'hooks/toolkit';
import useUserAccessFromPermission from 'hooks/useUserAccessFromPermission';
import TncPendingDetails from 'modules/tnc-pending/TnCPendingDetails';
import { RootState } from 'store';
import { UserAndMerchantStatusType } from 'types/api';
import { AcceptPendingTnCPayload } from 'types/tncApi.types';
import insertLinkInText from 'utils/insertLinkInText';
import { trackMixpanel } from 'utils/mixpanel';
import CommonWrapper from 'components/wrappers/CommonWrapper';

const TnCPending: FC = () => {
  const [viewedAt, setViewedAt] = useState<number>(Date.now());
  const [isCheckboxesChecked, setIsCheckboxesChecked] = useState<boolean>(false);
  const [acceptPendingTermsAndConditions, { isLoading }] = useAcceptPendingTermsAndConditionsMutation();
  const { user, merchantDetails } = useAppSelector((state: RootState) => state.user);

  const isUserActiveAndMerchantPending =
    user?.status === UserAndMerchantStatusType.ACTIVE &&
    merchantDetails?.status === UserAndMerchantStatusType.TNC_PENDING;

  const { data, isFetching } = useGetPendingTermsAndConditionsQuery(undefined, {
    skip: isUserActiveAndMerchantPending,
  });

  const handleLinkClick = () => setViewedAt(Date.now());

  const onClick = () => {
    const payload: AcceptPendingTnCPayload = {
      signed_at: Date.now(),
      viewed_at: viewedAt,
    };

    acceptPendingTermsAndConditions(payload);
  };

  const hasAccess = useUserAccessFromPermission(
    FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.permissionId,
    FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.scope
  );

  const isSubmitEnabled =
    hasAccess &&
    isCheckboxesChecked &&
    user?.status === UserAndMerchantStatusType.TNC_PENDING &&
    merchantDetails?.status === UserAndMerchantStatusType.TNC_PENDING;

  const isUserPendingAndMerchantActiveSubmitEnabled =
    hasAccess &&
    isCheckboxesChecked &&
    user?.status === UserAndMerchantStatusType.TNC_PENDING &&
    (merchantDetails?.status === UserAndMerchantStatusType.ACTIVE ||
      merchantDetails?.status === UserAndMerchantStatusType.INACTIVE);

  return (
    <>
      {isUserActiveAndMerchantPending ? (
        <div>
          We&apos;ve recently updated our terms and conditions. Kindly request your account owner(s) to accept them in
          order to access the account.
        </div>
      ) : (
        <CommonWrapper
          isLoading={isFetching}
          className='tw-flex tw-flex-col tw-items-center tw-h-full tw-justify-center'
        >
          <div className='tw-mt-[92px] tw-mb-[126px] tw-h-full tw-overflow-scroll'>
            <div className='tw-px-[22%]'>
              {data?.title && (
                <div className='f-20-500 tw-mb-2'>{insertLinkInText(data?.title, data?.links, handleLinkClick)}</div>
              )}
              {data?.description && (
                <div className='f-16-300 tw-text-TEXT_SECONDARY tw-mb-6'>
                  {insertLinkInText(data?.description, data?.links, handleLinkClick)}
                </div>
              )}
            </div>
            <div className='tw-h-[calc(100%-100px)] tw-overflow-scroll tw-px-[22%]'>
              {data?.sub_title && (
                <div className='f-16-300 tw-mb-4'>
                  {insertLinkInText(data?.sub_title, data?.links, handleLinkClick)}
                </div>
              )}
              <TncPendingDetails
                details={data?.details ?? []}
                links={data?.links ?? {}}
                onLinkClick={handleLinkClick}
                setIsCheckboxesChecked={setIsCheckboxesChecked}
              />
            </div>
          </div>
          <div className='tw-bg-BASE_SECONDARY tw-w-full tw-py-6 tw-px-[22%] tw-flex tw-flex-row-reverse tw-absolute tw-bottom-0 tw-items-center'>
            <Tooltip
              position={TooltipPositions.RIGHT}
              tooltipBody='You don’t have permission to accept the TnC.'
              disabled={isSubmitEnabled || isUserPendingAndMerchantActiveSubmitEnabled}
              tooltipBodystyle='tw-whitespace-nowrap tw-bg-TEXT_PRIMARY tw-text-white !tw-py-1.5 !tw-px-2 f-12-400 !tw-rounded'
              caratClassName=''
              color={COLORS.TEXT_PRIMARY}
            >
              <Button
                buttonProps={{
                  id: 'TNC_PENDING_ACCEPT_BUTTON',
                  eventCallback: trackMixpanel,
                  onClick: onClick,
                  isLoading,
                  size: BUTTON_SIZE_TYPES.MEDIUM,
                  wrapperClass: 'tw-w-24',
                  disabled: !isSubmitEnabled && !isUserPendingAndMerchantActiveSubmitEnabled,
                }}
              >
                Accept
              </Button>
            </Tooltip>
          </div>
        </CommonWrapper>
      )}
    </>
  );
};

export default TnCPending;
