import { StatusType } from 'constants/kyb';
import { BUTTON_SIZE_TYPES } from 'destiny/dist/constants';
import { defaultFnType } from 'types';
import { Map } from 'types/kyb';

export enum ErrorCardTypes {
  PAYMENT_FAIL,
  API_FAIL,
}

export enum NoDataBannerTypes {
  TRANSACTIONS,
  REPORTS,
  CUSTOM,
}

export interface PayoutsZeroDataType {
  wrapperClass?: string;
  handleClick?: defaultFnType;
  buttonText?: string;
  showButton?: boolean;
  title?: string;
  subtitle?: string | string[];
  imageSrc?: string;
  showUpperBanner?: boolean;
  contentClass?: string;
  buttonWrapperClass?: string;
}

export interface ZeroListDataType {
  handleClick?: defaultFnType;
  title?: string;
  subtitle?: string;
  buttonText?: string;
  lottieJson?: Map;
  lottieDimensions?: { width?: number | string; height?: number | string };
  destinyButtonProps?: Map;
  showButton?: boolean;
  preKybSubtitle?: string;
  wrapperClassName?: string;
  contentClassName?: string;
  titleClassName?: string;
  subtitleClassName?: string;
  preKybButtonText?: string;
  id: string;
  imageWrapperClassName?: string;
  imageWrapperStyle?: string;
  imageSrc?: string;
  productKybStatus?: StatusType;
  tooltipDisabled?: boolean;
  buttonSize?: BUTTON_SIZE_TYPES;
}
