import mixpanelEvents from 'constants/mixpanel';
import { useLogout } from 'hooks/useLogout';
import { trackMixpanel } from 'utils/mixpanel';
import ConfirmPopup from 'components/popup/ConfirmPopup';

const SessionExpired = () => {
  const handleLogout = useLogout();

  const handleContinue = () => {
    try {
      trackMixpanel(mixpanelEvents.SESSION_EXPIRY_ALERT_CLICK, {});
    } catch (e) {
      console.error('error', e);
    }

    handleLogout();
  };

  return (
    <ConfirmPopup
      isOpen={true}
      title='Session Expired'
      showClose={false}
      className='f-20-500 tw-text-TEXT_PRIMARY'
      wrapperClassName={`tw-w-full tw-max-w-[529px] tw-py-[32px] tw-px-[48px]`}
      childrenClassName='tw-border-none'
      titleClassName='tw-p-0'
      buttonClassName='tw-p-0'
      showCancel={false}
      submitBtnText={<div className='f-14-400 tw-flex'>Continue</div>}
      onSubmit={handleContinue}
    >
      <div className='tw-text-left'>
        <div className='f-16-300 tw-text-TEXT_SECONDARY tw-my-[32px]'>
          Your session has expired since you have reached the one-hour limit. You will now be redirected to the login
          page
        </div>
      </div>
    </ConfirmPopup>
  );
};

export default SessionExpired;
