export enum LAUNCH_DARKLY_FLAGS {
  // permanent flags
  CANARY = 'canary',
  OTC = 'otc',
  ROMA_GENERIC_BANNER = 'roma-generic-banner',

  // temporary flags
  POLICY_ANY_SOURCE_TYPE = 'new-source-type-any-policy',
  ROMA_PRICING_BANNER = 'roma-pricing-banner-v2',
  ACTIVATE_ACCOUNT_TOGGLE = 'activate-account-toggle',
  ACTIVATE_ACCOUNT_TOGGLE_LINK = 'activate-account-toggle-link',
}
