import { useEffect } from 'react';
import { captureException } from '@sentry/browser';
import { ERROR_TOKENS } from 'constants/index';
import mixpanelEvents from 'constants/mixpanel';
import { useAppSelector } from 'hooks/toolkit';
import { RootState } from 'store';
import { trackMixpanel } from 'utils/mixpanel';
import ZeroState from 'components/ZeroState';

interface ErrorPageProps {
  className?: string;
  errorCode?: string;
  title?: string;
  description?: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  className = '',
  errorCode = ERROR_TOKENS.PAGE_BREAK,
  title,
  description,
}) => {
  const userData = useAppSelector((state: RootState) => state.user);

  useEffect(() => {
    try {
      trackMixpanel(mixpanelEvents.PAGE_ISSUE_TRACKER, { code: errorCode });
      captureException(new Error(errorCode), {
        extra: { path: window?.location?.pathname, user: userData?.user?.id, title, description },
      });
    } catch (e) {
      console.error(errorCode, { path: window?.location?.pathname, user: userData?.user?.id });
    }
  }, []);

  return (
    <div className={`tw-flex tw-justify-center tw-items-center ${className}`}>
      <ZeroState title={title} description={description} />
    </div>
  );
};

export default ErrorPage;
