import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { MapAny } from 'types';
import { COUNTRY_OPTIONS } from 'components/country-picker/countries';

interface CountryLabelProps {
  details: MapAny;
  className?: string;
  merchantName?: string;
  overrideClassName?: string;
}

const CountryLabel: React.FC<CountryLabelProps> = ({
  details,
  className = '',
  merchantName,
  overrideClassName = 'f-16-400',
}) => {
  const countryDetails = COUNTRY_OPTIONS?.find((each) => each.code === details?.geography);

  return (
    <div className={`tw-text-TEXT_PRIMARY tw-flex tw-gap-3 tw-items-center ${className} ${overrideClassName}`}>
      <SvgSpriteLoader
        id={countryDetails?.iconAvailable ? countryDetails?.code : 'GENERAL'}
        iconCategory={ICON_SPRITE_TYPES.COUNTRY_FLAGS}
        viewBox={'0 0 26 26'}
        width={24}
        height={24}
        className={`tw-min-w-[24px]`}
      />
      <div>
        {details?.entity_name || merchantName} | <span className='tw-text-TEXT_TERTIARY'>{countryDetails?.name}</span>
      </div>
    </div>
  );
};

export default CountryLabel;
