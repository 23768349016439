import { ERROR_WITH_BORDER, PAYMENT_FAILED_V2 } from 'constants/icons';
import { Button } from 'destiny/dist/components/molecules/button';
import { defaultFn } from 'destiny/dist/constants/index';
import { BUTTON_SIZE_TYPES, BUTTON_TYPES } from 'destiny/dist/constants/molecules/buttons';
import Image from 'next/image';
import { trackMixpanel } from 'utils/mixpanel';
import { ErrorCardTypes } from 'components/banners/types';

interface ErrorCardPropTypes {
  className?: string;
  onClose?: any;
  title?: string;
  type?: ErrorCardTypes;
  isLoading?: boolean;
  height?: number;
  subtitle?: string;
  customWrapperClassName?: string;
  customImageDimensions?: { width?: number; height?: number };
  customTitleClassName?: string;
  customSubtitleClassName?: string;
  refetchButtonId?: string;
}

const ErrorCard: React.FC<ErrorCardPropTypes> = ({
  className,
  onClose = defaultFn,
  title = 'Payment Failed',
  type = ErrorCardTypes.PAYMENT_FAIL,
  isLoading = false,
  height,
  subtitle = 'Something went wrong please try again...',
  customWrapperClassName = null,
  customImageDimensions = null,
  customTitleClassName = null,
  customSubtitleClassName = null,
  refetchButtonId = '',
}) => {
  switch (type) {
    case ErrorCardTypes.PAYMENT_FAIL: {
      const imageStyle = customImageDimensions ?? { height: 159, width: 156 };

      return (
        <div
          className={`${
            customWrapperClassName ?? 'tw-pt-12 tw-pb-10 tw-w-[480px]'
          } tw-flex tw-items-center tw-flex-col ${className}`}
        >
          <Image src={PAYMENT_FAILED_V2} alt='Payment failed' {...imageStyle} />
          <div className={customTitleClassName ?? 'f-24-600 tw-text-TEXT_PRIMARY tw-mt-6'}>{title}</div>
          <div className={customSubtitleClassName ?? 'f-16-300 tw-text-TEXT_TERTIARY tw-mt-6'}> {subtitle} </div>
          <Button
            buttonProps={{
              btnType: BUTTON_TYPES.PRIMARY,
              isLoading: isLoading,
              size: BUTTON_SIZE_TYPES.LARGE,
              onClick: onClose,
              eventCallback: refetchButtonId ? trackMixpanel : defaultFn,
              wrapperClass: 'tw-mt-10 tw-w-40',
              id: refetchButtonId,
            }}
          >
            <span className='f-12-400'>Try again</span>
          </Button>
        </div>
      );
    }
    case ErrorCardTypes.API_FAIL: {
      return (
        <div className={`tw-flex tw-items-center ${className}`} style={{ minHeight: height && height + 'px' }}>
          <div className='tw-w-full tw-flex tw-justify-center tw-py-6'>
            <div>
              <Image src={ERROR_WITH_BORDER} alt='api-fallback' height={20} width={20} />
            </div>
            <div className='tw-ml-2.5 '>
              <div className='f-16-400 tw-mb-4'>{subtitle}</div>
              <div>
                <Button
                  buttonProps={{
                    btnType: BUTTON_TYPES.SECONDARY,
                    isLoading: isLoading,
                    size: BUTTON_SIZE_TYPES.SMALL,
                    onClick: onClose,
                    eventCallback: refetchButtonId ? trackMixpanel : defaultFn,
                    id: refetchButtonId,
                  }}
                >
                  <span className='f-12-400'>Try again</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    default:
      return <></>;
  }
};

export default ErrorCard;
