interface LoaderProps {
  width?: string;
  height?: string;
}

export const Loader: React.FC<LoaderProps> = ({ width = '68px', height = '68px' }) => {
  return (
    <div
      style={{ width, height }}
      className='animate-spin tw-border-[6px] tw-border-transparent tw-rounded-full tw-border-t-blue-500 tw-border-r-blue-500 tw-border-l-blue-500'
    ></div>
  );
};
