import { ACCESS_SCOPES, PERMISSION_NAMES } from 'types/access';

export const SCOPE_PERMISSION_RESOURCE_MAP = {
  [ACCESS_SCOPES.MERCHANTS]: 'merchants',
  [ACCESS_SCOPES.ENTITIES]: 'entities',
  [ACCESS_SCOPES.VAULTS]: 'vaults',
  [ACCESS_SCOPES.ACCOUNTS]: 'accounts',
};

export const FLOW_PERMISSION_PROPERTIES = {
  MANAGE_TEAM: {
    permissionId: PERMISSION_NAMES.PLATFORM_TEAM_MANAGE,
    scope: ACCESS_SCOPES.MERCHANTS,
  },
  MANAGE_ROLES: {
    permissionId: PERMISSION_NAMES.PLATFORM_TEAM_MANAGE,
    scope: ACCESS_SCOPES.MERCHANTS,
  },
  MANAGE_POLICIES: {
    permissionId: PERMISSION_NAMES.PLATFORM_POLICIES_MANAGE,
    scope: ACCESS_SCOPES.MERCHANTS,
  },
  MANAGE_COMPANIES: {
    permissionId: PERMISSION_NAMES.PLATFORM_COMPANIES_MANAGE,
    scope: ACCESS_SCOPES.MERCHANTS,
  },
  CREATE_MERCHANT_ACCOUNTS: {
    permissionId: PERMISSION_NAMES.ACCOUNTS_CREATE,
    scope: ACCESS_SCOPES.MERCHANTS,
  },
  CREATE_ACCOUNTS: {
    permissionId: PERMISSION_NAMES.ACCOUNTS_CREATE,
    scope: ACCESS_SCOPES.ENTITIES,
  },
  VIEW_MERCHANT_ACCOUNTS: {
    permissionId: PERMISSION_NAMES.ACCOUNTS_VIEW,
    scope: ACCESS_SCOPES.MERCHANTS,
  },
  VIEW_ENTITY_ACCOUNTS: {
    permissionId: PERMISSION_NAMES.ACCOUNTS_VIEW,
    scope: ACCESS_SCOPES.ENTITIES,
  },
  VIEW_ACCOUNT_DETAILS: {
    permissionId: PERMISSION_NAMES.ACCOUNTS_VIEW,
    scope: ACCESS_SCOPES.ACCOUNTS,
  },
  VIEW_TREASURY_INVESTMENTS: {
    permissionId: PERMISSION_NAMES.TREASURY_INVESTMENTS_VIEW,
    scope: ACCESS_SCOPES.MERCHANTS,
  },
  MANAGE_TREASURY_INVESTMENTS: {
    permissionId: PERMISSION_NAMES.TREASURY_INVESTMENTS_MANAGE,
    scope: ACCESS_SCOPES.MERCHANTS,
  },
  MANAGE_TREASURY_WITHDRAWAL: {
    permissionId: PERMISSION_NAMES.TREASURY_WITHDRAWAL_MANAGE,
    scope: ACCESS_SCOPES.MERCHANTS,
  },
  VIEW_CARDS: {
    permissionId: PERMISSION_NAMES.CARDS_VIEW,
    scope: ACCESS_SCOPES.MERCHANTS,
  },
  MANAGE_CARDS: {
    permissionId: PERMISSION_NAMES.CARDS_MANAGE,
    scope: ACCESS_SCOPES.VAULTS,
  },
  VIEW_TRANSACTIONS: {
    permissionId: PERMISSION_NAMES.ACCOUNTS_TRANSACTIONS_VIEW,
    scope: ACCESS_SCOPES.MERCHANTS,
  },
  VIEW_ACCOUNT_TRANSACTIONS: {
    permissionId: PERMISSION_NAMES.ACCOUNTS_TRANSACTIONS_VIEW,
    scope: ACCESS_SCOPES.ACCOUNTS,
  },
  MANAGE_RECEPIENTS: {
    permissionId: PERMISSION_NAMES.ACCOUNTS_RECIPIENTS_MANAGE,
    scope: ACCESS_SCOPES.MERCHANTS,
  },
  VIEW_REPORTS: {
    permissionId: PERMISSION_NAMES.ACCOUNTS_REPORTS_VIEW,
    scope: ACCESS_SCOPES.MERCHANTS,
  },
  UPDATE_PAYOUT_REQUESTS: {
    permissionId: PERMISSION_NAMES.BANKING_PAYOUT_APPROVE,
    scope: ACCESS_SCOPES.MERCHANTS,
  },
  EXECUTE_PAYMENT: {
    permissionId: PERMISSION_NAMES.BANKING_PAYOUT_APPROVE,
    scope: ACCESS_SCOPES.ACCOUNTS,
  },
  SEND_MONEY: {
    permissionId: PERMISSION_NAMES.BANKING_PAYOUT_CREATE,
    scope: ACCESS_SCOPES.MERCHANTS,
  },
  REQUEST_PAYOUT: {
    permissionId: PERMISSION_NAMES.BANKING_PAYOUT_CREATE,
    scope: ACCESS_SCOPES.ACCOUNTS,
  },
  APPROVE_PAYMENT: {
    permissionId: PERMISSION_NAMES.BANKING_PAYOUT_CREATE,
    scope: ACCESS_SCOPES.ACCOUNTS,
  },
  VIEW_PAYMENT_REQUESTS: {
    permissionId: null,
    scope: ACCESS_SCOPES.MERCHANTS,
  },
  VIEW_PENDING_WALLETS: {
    permissionId: null,
    scope: ACCESS_SCOPES.MERCHANTS,
  },
};

export const NO_PERMISSIONS_TEXT = "You don't have permission to access this";

export const UNAUTHORIZED_ERROR_CODE = 'UNAUTHORIZED_ROLE';
