import { CSSProperties, FC, useEffect, useState } from 'react';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { defaultFnType } from 'types';
import Popup from 'components/popup/Popup';
import PopupActionFooter from 'components/popup/PopupActionFooter';
import PopupHeader from 'components/popup/PopupHeader';

type SideDrawerProps = {
  isOpen: boolean;
  onClose: (state: any | null) => void;
  children: React.ReactNode;
  className?: string;
  bodyClassName?: string;
  hideCloseButton?: boolean;
  style?: CSSProperties;
  closeBtnStyle?: string;
  closeOnClickOutside?: boolean;
  onClickOutside?: defaultFnType;
  stackPosition?: number;
  showBackDrop?: boolean;
  id?: string;
  showExitConfirmationPopUp?: boolean;
};

const SideDrawer: FC<SideDrawerProps> = ({
  isOpen = false,
  onClose,
  children,
  className = '',
  bodyClassName = 'tw-w-[670px] tw-h-screen tw-bg-white',
  hideCloseButton,
  style = {},
  closeBtnStyle = '',
  onClickOutside,
  closeOnClickOutside = true,
  stackPosition = 0,
  showBackDrop = true,
  id = '',
  showExitConfirmationPopUp = false,
}) => {
  const [isMount, setIsMount] = useState(true);
  const [isShowPopUp, setIsShowPopUp] = useState(false);

  const handleClickOutside = (e: React.MouseEvent<HTMLDivElement>) => {
    e?.stopPropagation();
    onClickOutside?.();

    if (closeOnClickOutside) {
      if (showExitConfirmationPopUp) {
        handleToggleConfirmationPopup();
      } else {
        handleClose();
      }
    }
  };

  const stackedClassNames: Record<number, string> = {
    0: '',
    1: 'tw-w-sideDrawer1 tw-h-sideDrawer1',
    2: 'tw-w-sideDrawer2 tw-h-sideDrawer2',
    3: 'tw-w-sideDrawer3 tw-h-sideDrawer3',
    4: 'tw-w-sideDrawer4 tw-h-sideDrawer4',
    5: 'tw-w-sideDrawer5 tw-h-sideDrawer5',
  };

  const handleClose = () => {
    setIsMount(false);
    setTimeout(() => onClose(null), 200);
  };

  const handleToggleConfirmationPopup = () => setIsShowPopUp((prev) => !prev);

  const handleCloseWrapper = () => {
    if (showExitConfirmationPopUp) handleToggleConfirmationPopup();
    else handleClose();
  };

  useEffect(() => {
    if (isOpen) setIsMount(true);
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      className={`tw-h-full tw-flex tw-justify-end tw-fixed tw-w-screen tw-z-[150] tw-top-0 tw-left-0 tw-items-center ${
        showBackDrop ? 'tw-bg-BACKDROP_BLUE' : ''
      } ${isOpen ? '' : 'tw-hidden'}`}
      role='presentation'
      onClick={(e: React.MouseEvent<HTMLDivElement>) => handleClickOutside(e)}
    >
      <div
        className={`${
          isMount ? 'tw-animate-sideDrawer-mount' : 'tw-animate-sideDrawer-unMount'
        } -right-[100vw] tw-absolute tw-transition-all tw-shadow-sideDrawer ${bodyClassName} ${
          stackedClassNames[stackPosition]
        } ${className}`}
        role='presentation'
        onClick={(e) => e.stopPropagation()}
        style={style}
      >
        {!hideCloseButton && (
          <div
            className={`tw-w-9 tw-z-50 tw-h-9 tw-bg-GRAY_2 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-absolute tw-top-[50px] tw-right-[72px] tw-cursor-pointer ${closeBtnStyle}`}
            role='presentation'
            onClick={handleCloseWrapper}
            data-testid={`side-drawer-close-button-${id}`}
          >
            <SvgSpriteLoader id='x-close' iconCategory={ICON_SPRITE_TYPES.GENERAL} width={16} height={16} />
          </div>
        )}
        {children}
      </div>
      {isShowPopUp && (
        <Popup isOpen={isShowPopUp} handleVisibility={handleToggleConfirmationPopup}>
          <div className='tw-bg-white tw-w-sideDrawer1 tw-rounded-2.5 tw-p-8'>
            <PopupHeader title='Are you sure?' handleClose={handleToggleConfirmationPopup} />
            <div>Are you sure you want to leave? Your progress will be lost.</div>
            <PopupActionFooter
              id='SIDEDRAWER_CONFIRMATION_POPUP'
              showPrimaryCta
              showSecondaryCta
              primaryCtaTitle='Leave'
              secondaryCtaTitle='Continue'
              handleSecondaryAction={handleToggleConfirmationPopup}
              handlePrimaryAction={handleClose}
            />
          </div>
        </Popup>
      )}
    </div>
  );
};

export default SideDrawer;
