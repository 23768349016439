import SkeletonComponent from 'components/skeletons/SkeletonComponent';

const IntentSkeleton = ({ className = '' }) => (
  <div className={`tw-mx-auto ${className}`}>
    <SkeletonComponent />
    <SkeletonComponent />
    <SkeletonComponent />
  </div>
);

export default IntentSkeleton;
