import { FC } from 'react';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { Tooltip } from 'destiny/dist/components/molecules/tooltip/index';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { TooltipPositions } from 'destiny/dist/constants/molecules/tooltip';
import { useIsSuperUser } from 'hooks';
import { defaultFnType } from 'types';

type FormToolTipProps = {
  label?: string;
  tooltip?: string;
  labelClassName?: string;
  linkGeneration?: boolean;
  onGenerateLink?: defaultFnType;
  id?: string;
};

const FormToolTip: FC<FormToolTipProps> = ({
  tooltip,
  label,
  labelClassName = '',
  linkGeneration = false,
  onGenerateLink,
  id,
}) => {
  const isSuperUser = useIsSuperUser();

  return (
    <div className='f-16-400 tw-mb-2 tw-flex tw-items-center tw-text-TEXT_GRAY_2'>
      {label && (
        <div className={labelClassName} data-testid={`form-tooltip-label-${id}`}>
          {label}
        </div>
      )}

      {tooltip && (
        <Tooltip
          tooltipBody={tooltip}
          position={TooltipPositions.BOTTOM_LEFT}
          color={COLORS.TEXT_PRIMARY}
          tooltipBodyClassName='f-12-300 tw-py-1 tw-px-2 tw-rounded-md tw-w-[100px]'
        >
          <SvgSpriteLoader
            id='info-square'
            iconCategory={ICON_SPRITE_TYPES.GENERAL}
            width={16}
            height={16}
            className='tw-ml-1 tw-mt-0.5'
          />
        </Tooltip>
      )}
      {linkGeneration && isSuperUser && (
        <SvgSpriteLoader
          id='link-03'
          iconCategory={ICON_SPRITE_TYPES.GENERAL}
          width={16}
          height={16}
          className='tw-ml-auto tw-mr-1 tw-cursor-pointer'
          onClick={onGenerateLink}
        />
      )}
    </div>
  );
};

export default FormToolTip;
