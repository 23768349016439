import { createSlice } from '@reduxjs/toolkit';

export type MfaState = {
  totpData: object;
};

const initialState: MfaState = {
  totpData: { totp_secret: '', qr_code: '', token: '' },
};

export const userSlice = createSlice({
  name: 'mfa',
  initialState,
  reducers: {
    setMfaInitiateTotpData: (state, action) => {
      state.totpData = action.payload;
    },
  },
});

export const { setMfaInitiateTotpData } = userSlice.actions;

export default userSlice.reducer;
