export enum DEFAULT_ROLES {
  OWNER = 'Owner',
}

export enum ACCESS_SCOPES {
  MERCHANTS = 'merchant',
  ENTITIES = 'entity',
  VAULTS = 'vault',
  ACCOUNTS = 'account',
}

export enum PERMISSION_NAMES {
  ACCOUNTS_VIEW = 'accounts.view',
  ACCOUNTS_TRANSACTIONS_VIEW = 'accounts.transactions.view',
  ACCOUNTS_REPORTS_VIEW = 'accounts.reports.view',
  ACCOUNTS_CREATE = 'accounts.create',
  ACCOUNTS_RECIPIENTS_MANAGE = 'accounts.recipients.manage',
  BANKING_PAYOUT_CREATE = 'banking.payout.create',
  BANKING_PAYOUT_APPROVE = 'banking.payout.approve',
  TREASURY_WITHDRAWAL_MANAGE = 'treasury.withdrawal.manage',
  TREASURY_INVESTMENTS_VIEW = 'treasury.investments.view',
  TREASURY_INVESTMENTS_MANAGE = 'treasury.investments.manage',
  CARDS_GET = 'cards.get',
  CARDS_VIEW = 'cards.view',
  CARDS_MANAGE = 'cards.manage',
  PLATFORM_TEAM_MANAGE = 'platform.team.manage',
  PLATFORM_POLICIES_MANAGE = 'platform.policies.manage',
  PLATFORM_COMPANIES_MANAGE = 'platform.companies.manage',
}

export type PermissionType = {
  id: string;
  name: PERMISSION_NAMES;
  display_name: string;
  description: string;
  resources: Record<ACCESS_SCOPES, string[] | null>;
};
