import { useEffect } from 'react';
import { captureException } from '@sentry/browser';
import { NO_PERMISSIONS } from 'constants/icons';
import { ERROR_TOKENS } from 'constants/index';
import mixpanelEvents from 'constants/mixpanel';
import { useAppSelector } from 'hooks/toolkit';
import Image from 'next/image';
import { RootState } from 'store';
import { trackMixpanel } from 'utils/mixpanel';

const NoPermissionState = ({
  title = "You don't have the necessary permissions to access this information",
  description = '',
  className = '',
}) => {
  const { user } = useAppSelector((state: RootState) => state.user);

  useEffect(() => {
    try {
      trackMixpanel(mixpanelEvents.PAGE_ISSUE_TRACKER, { code: ERROR_TOKENS.NO_PERMISSIONS_PAGE });
      captureException(new Error(ERROR_TOKENS.NO_PERMISSIONS_PAGE), {
        extra: { path: window?.location?.pathname, user: user?.id, title, description },
      });
    } catch (e) {
      console.error(ERROR_TOKENS.NO_PERMISSIONS_PAGE, { path: window?.location?.pathname, user: user?.id });
    }
  }, []);

  return (
    <div className={`tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-3 ${className}`}>
      <Image src={NO_PERMISSIONS} alt='no_permissions' width={200} height={80} className='tw-mb-5' />
      <div className='f-20-400 tw-w-96 tw-text-center'>{title}</div>
      {description && <div className='f-16-300 tw-text-TEXT_SECONDARY'>{description}</div>}
    </div>
  );
};

export default NoPermissionState;
