import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetPayoutsByBulkIdResponse } from 'types/api';
import { BulkPayout } from 'types/transactions';

export type BulkTransactionState = {
  payouts: BulkPayout[];
  totalAmount: number;
  totalFees: number;
  totalBeneficiaries: number;
  sourceCurrencyCode: string;
  payoutId: string;
  network: string;
  bulkTxnResponseString: string;
  errorRows: BulkPayout[];
  columnList: string[];
  bulkTxnResponse?: GetPayoutsByBulkIdResponse;
  sumAmount?: number;
};

const initialState: BulkTransactionState = {
  payouts: [],
  totalAmount: 0,
  totalFees: 0,
  totalBeneficiaries: 0,
  sourceCurrencyCode: '',
  payoutId: '',
  network: '',
  bulkTxnResponseString: '',
  errorRows: [],
  columnList: [],
  bulkTxnResponse: undefined,
  sumAmount: 0,
};

export const bulkTransactionsSlice = createSlice({
  name: 'bulk-payout',
  initialState,
  reducers: {
    updateInitiatedPayouts: (state, action: PayloadAction<GetPayoutsByBulkIdResponse>) => {
      const {
        payouts,
        total_amount,
        total_fees,
        total_beneficiaries,
        source_currency_code,
        id,
        network,
        error_rows,
        columns,
        sum_amount,
      } = action.payload;

      state.payouts = payouts;
      state.totalAmount = total_amount;
      state.totalBeneficiaries = total_beneficiaries;
      state.totalFees = total_fees;
      state.sourceCurrencyCode = source_currency_code;
      state.payoutId = id;
      state.network = network;
      state.bulkTxnResponseString = JSON.stringify(action.payload);
      state.errorRows = error_rows?.map((item) => payouts[item - 1]);
      state.columnList = columns;
      state.bulkTxnResponse = action.payload;
      state.sumAmount = sum_amount;
    },
  },
});

export const { updateInitiatedPayouts } = bulkTransactionsSlice.actions;

export default bulkTransactionsSlice.reducer;
