import { useMemo } from 'react';
import { useAppSelector } from 'hooks/toolkit';
import { ACCESS_SCOPES, PERMISSION_NAMES } from 'types/access';
import { getUserAccessFromPermissions } from 'utils/access';

const useUserAccessFromPermission = (
  permissionId: PERMISSION_NAMES,
  scope: ACCESS_SCOPES,
  resourceId?: string | null
) => {
  const {
    user: { merchant_id, role },
    permissions,
  } = useAppSelector((state) => state.user);

  const hasAccess = useMemo(
    () => getUserAccessFromPermissions(permissionId, scope, resourceId ?? merchant_id, permissions ?? []),
    [permissions, role]
  );

  return hasAccess;
};

export default useUserAccessFromPermission;
