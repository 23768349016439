import { FC } from 'react';

interface SkeletonProps {
  height?: number;
  className?: string;
}

const elemtnStyling = 'tw-h-4 tw-bg-slate-200 tw-rounded';

const SkeletonCard: FC<SkeletonProps> = ({ height = 100, className }) => {
  return (
    <div className={`tw-p-4 tw-border-DIVIDER_GRAY tw-shadow tw-rounded-md ${className}`}>
      <div style={{ height: height + 'px' }} className={` tw-overflow-hidden `}>
        <div className='tw-animate-pulse tw-flex tw-space-x-4'>
          <div className='tw-flex-1 tw-space-y-6 tw-py-1'>
            <div className={`${elemtnStyling} tw-w-[10%]`}></div>
            <div className={`${elemtnStyling} tw-w-[60%]`}></div>
          </div>
        </div>
        <div className='tw-animate-pulse tw-flex tw-space-x-4'>
          <div className='tw-flex-1 tw-space-y-6 tw-py-1'>
            <div className={`${elemtnStyling} tw-w-[10%]`}></div>
            <div className={`${elemtnStyling} tw-w-[60%]`}></div>
          </div>
        </div>
        <div className='tw-animate-pulse tw-flex tw-space-x-4'>
          <div className='tw-flex-1 tw-space-y-6 tw-py-1'>
            <div className={`${elemtnStyling} tw-w-[10%]`}></div>
            <div className={`${elemtnStyling} tw-w-[60%]`}></div>
          </div>
        </div>
        <div className='tw-animate-pulse tw-flex tw-space-x-4'>
          <div className='tw-flex-1 tw-space-y-6 tw-py-1'>
            <div className={`${elemtnStyling} tw-w-[10%]`}></div>
            <div className={`${elemtnStyling} tw-w-[60%]`}></div>
          </div>
        </div>
        <div className='tw-animate-pulse tw-flex tw-space-x-4'>
          <div className='tw-flex-1 tw-space-y-6 tw-py-1'>
            <div className={`${elemtnStyling} tw-w-[10%]`}></div>
            <div className={`${elemtnStyling} tw-w-[60%]`}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonCard;
