import { type FC } from 'react';
import { MenuOption } from 'destiny/dist/components/molecules/menuOption';
import { defaultFnType } from 'destiny/dist/types';
import { KybTncConfigType } from 'modules/kyb/kyb.types';
import { LinkType } from 'types/tncApi.types';
import insertLinkInText from 'utils/insertLinkInText';
import { trackMixpanel } from 'utils/mixpanel';
import Points from 'components/NestedBulletList';

type KybTncDetailProps = {
  item: KybTncConfigType;
  index: number;
  onCheckBoxClick: (index: number) => void;
  links: Record<string, LinkType>;
  checkboxValues: boolean[];
  onLinkClick?: defaultFnType;
};

const KybTncDetail: FC<KybTncDetailProps> = ({
  item,
  index,
  onCheckBoxClick,
  links = {},
  checkboxValues = [],
  onLinkClick,
}) => (
  <div>
    {!!item.heading && <h6 className='f-16-700 tw-underline tw-mb-4'>{item.heading}</h6>}
    {!!item.description && <p>{insertLinkInText(item.description, links, onLinkClick)}</p>}
    {!!item.points && <Points points={item.points} links={links} />}
    {!!item.checkbox_label && (
      <MenuOption
        isMulti
        innerProps={{ id: 'KYB_TNC_DESCRIPTION' }}
        eventCallback={trackMixpanel}
        label={item.checkbox_label}
        onClick={() => onCheckBoxClick(index)}
        isSelected={checkboxValues[index]}
        checkboxDisplayContainerClassName='!tw-left-0'
        checkboxClassName='!tw-pl-0'
        containerClass='hover:tw-bg-white'
        wrapperClass='tw-flex tw-items-center tw-h-fit'
      />
    )}
  </div>
);

export default KybTncDetail;
