import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { ACCESS_HEADER_RESOURCE_KEY, PLATFORM_IDEMPOTENCY_HEADER_KEY } from 'constants/config';
import { APITags, REQUEST_TYPES } from 'constants/index';
import baseApi from 'services/api';
import { B2bUserConfigurtionType, GetUserResponseType, UserPermissionsResponseType } from 'types/api';
import { UpdateUserRoleRequestType } from 'types/userApi.types';
import { accessBasedQueryWrapper } from 'utils/access';

const User = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<GetUserResponseType, void>({
      query: () => `v1/b2b-user`,
      transformResponse: ({ data }) => data,
      providesTags: [APITags.GET_USER],
    }),
    getUserNotificationConfig: builder.query<B2bUserConfigurtionType, void>({
      query: () => `v1/b2b-user/config`,
      transformResponse: ({ data }) => data,
    }),
    patchUserNotificationConfig: builder.mutation<B2bUserConfigurtionType, B2bUserConfigurtionType>({
      query: (payload) => ({
        url: `v1/b2b-user/config`,
        method: REQUEST_TYPES.PATCH,
        body: payload,
      }),
      transformResponse: ({ data }) => data,
    }),
    updateRoleForUser: builder.mutation<void, UpdateUserRoleRequestType>({
      query: (payload) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_TEAM.permissionId, {
          url: `v1/users/${payload.userId}/roles`,
          method: REQUEST_TYPES.PATCH,
          headers: {
            [PLATFORM_IDEMPOTENCY_HEADER_KEY]: payload.idempotencyHeader,
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_TEAM.scope,
          },
          body: payload.data,
        }),
      transformResponse: ({ data }) => data,
      invalidatesTags: (_, error) => (!error ? [APITags.GET_TEAMS] : []),
    }),
    getUserPermission: builder.query<UserPermissionsResponseType, void>({
      query: () => `v1/users/permissions`,
      transformResponse: ({ data }) => data,
      providesTags: [APITags.GET_USER_PERMISSIONS],
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetUserNotificationConfigQuery,
  usePatchUserNotificationConfigMutation,
  useUpdateRoleForUserMutation,
  useLazyGetUserPermissionQuery,
} = User;
