import { useEffect, useState } from 'react';
import { useCreateSessionTokenMutation } from 'api/platform';
import { defaultFn } from 'destiny/dist/constants/index';
import { CreateSessionPayload } from 'types/api';

export const useSessionToken = (capabilityContextKey: string, skip = false) => {
  const [getSessionToken] = useCreateSessionTokenMutation();
  const [sessionToken, setSessionToken] = useState<null | string>(null);

  const createSessionToken = async () => {
    try {
      const payload: CreateSessionPayload = {
        capability_context: capabilityContextKey,
      };

      const result = await getSessionToken(payload).unwrap();

      if (result?.id) setSessionToken(result.id);
    } catch (e: any) {
      defaultFn();
    }
  };

  useEffect(() => {
    !skip && createSessionToken();
  }, [skip]);

  return { sessionToken, createSessionToken };
};
