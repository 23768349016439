import { REQUEST_TYPES } from 'constants/index';
import baseApi from 'services/api';
import { CreateSessionPayload, IdempotencySessionResponse } from 'types/api';

const Platform = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createSessionToken: builder.mutation<IdempotencySessionResponse, CreateSessionPayload>({
      query: (payload) => ({
        url: 'v1/nonce',
        method: REQUEST_TYPES.POST,
        body: payload,
      }),
      transformResponse: ({ data }) => data,
    }),
  }),
});

export const { useCreateSessionTokenMutation } = Platform;
