import { useEffect, useState } from 'react';
import mixpanelEvents from 'constants/mixpanel';
import { trackMixpanel } from 'utils/mixpanel';

function NetworkStatus() {
  const [isOffline, setIsOffline] = useState(false);

  const handleOnline = () => {
    setIsOffline(false);
    trackMixpanel(mixpanelEvents.NETWORK_TOGGLE, { status: 'online' });
  };
  const handleOffline = () => {
    setIsOffline(true);
    trackMixpanel(mixpanelEvents.NETWORK_TOGGLE, { status: 'offline' });
  };

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      {isOffline && (
        <div
          className={`tw-flex tw-items-center offline-tranision  tw-left-[45%] tw-top-[5%] transition ease-in-out tw-fixed  tw-rounded-xl tw-bg-[#fff] tw-border-solid tw-border tw-shadow-2xl  tw-border-[#ffccc7] tw-p-2.5 tw-px-6 tw-text-sm tw-z-[100]`}
        >
          <div className='tw-bg-[#f6e3e0] tw-rounded-xl tw-p-3'>
            <svg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M0.75 4.37503C1.42002 3.78875 2.2163 3.30068 3.09655 2.91082M3.25 7.81246C4.2035 6.93845 5.40954 6.34222 6.70759 6.02378M5.5 11.0625C7.5 9.3125 10.5 9.3125 12.5 11.0625M16.25 14.25L6.70759 6.02378M0.75 0.75L3.09655 2.91082M3.09655 2.91082L6.70759 6.02378M6.32326 1.96833C10.2289 1.31808 14.6732 2.12032 17.25 4.37505M10.9344 5.94342C12.3679 6.23555 13.7094 6.85855 14.75 7.81246M10.25 15C10.25 15.6904 9.69036 16.25 9 16.25C8.30964 16.25 7.75 15.6904 7.75 15C7.75 14.3096 8.30964 13.75 9 13.75C9.69036 13.75 10.25 14.3096 10.25 15Z'
                stroke='#382a2a'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>

          <div className='tw-text-center tw-ml-6'>
            <div className='f-14-500'> It seems that you are offline!</div>
            <div className='f-12-400'> Please check your connection and try again</div>
          </div>
        </div>
      )}
    </>
  );
}
export default NetworkStatus;
