import { FC } from 'react';

interface SkeletonProps {
  height?: number;
  count?: number;
}

const elemtn1Styling = 'tw-h-6 tw-bg-slate-200 tw-rounded';
const elemtn2Styling = 'tw-h-4 tw-bg-slate-200 tw-rounded';
const ContainerStyling = 'tw-flex-1 tw-space-y-6 tw-py-1';

const SkeletonComponent: FC<SkeletonProps> = ({ height = 100, count = 1 }) => {
  const SkeletonsList = Array(count).fill('');

  return (
    <>
      {SkeletonsList.map((_, idx) => (
        <div key={idx} className='tw-p-4 tw-border-DIVIDER_GRAY tw-shadow tw-rounded-md  tw-mb-4'>
          <div style={{ height: height + 'px' }} className={` tw-overflow-hidden `}>
            <div className='tw-animate-pulse tw-flex tw-space-x-4'>
              <div className={`${ContainerStyling}`}>
                <div className={`${elemtn1Styling} tw-w-[40%]`}></div>
                <div className={`${elemtn2Styling} tw-w-[80%]`}></div>
              </div>
            </div>
            <div className='tw-animate-pulse tw-flex tw-space-x-4'>
              <div className={`${ContainerStyling}`}>
                <div className={`${elemtn2Styling} tw-w-[100%]`}></div>
              </div>
            </div>
            <div className='tw-animate-pulse tw-flex tw-space-x-4'>
              <div className={`${ContainerStyling}`}>
                <div className='tw-h-10 tw-bg-slate-200 tw-rounded tw-w-[10%]'></div>
                <div className={`${elemtn2Styling} tw-w-[60%]`}></div>
              </div>
            </div>
            <div className='tw-animate-pulse tw-flex tw-space-x-4 tw-mt-10'>
              <div className={`${ContainerStyling}`}>
                <div className={`${elemtn2Styling} tw-w-[100%]`}></div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default SkeletonComponent;
